import './App.css';


const Csr = () => {

    return (
        <>
            <div className='h-20'></div>
            <div className='heading-rounded m-auto text-center text-white font-bold py-4 w-11/12 md:w-3/5'>CORPORATE SOCIAL RESPONSIBILITY (“CSR”) POLICY</div>
            <section>
                <div className='container px-6 xl:px-0 xl:w-4/5 2xl:container mx-auto py-20'>
                    <p className='name mb-5'>PREAMBLE</p>
                    <p className='pointss mb-5 text-justify'>As a responsible corporate citizen, IARC is committed to creating a positive impact to the environment 
                        in which it operates. Our approach is to integrate the activities in community development, social 
                        responsibility and environmental responsibility and encourage each investee companies/business unit 
                        to include these considerations into its operations.
                    </p>
                    <p className='pointss mb-5 text-justify'>
                        This policy is in line with Section 135 of the Companies Act, 2013 (“Act”) and The Companies (Corporate 
                        Social Responsibility Policy) Rules, 2014 (“CSR Rules”) and will apply to all projects/programs 
                        undertaken as part of the IARC’s CSR activities and will be developed, reviewed, and updated by 
                        reference to relevant codes of corporate governance, best practices and international standards. This 
                        “Corporate Social Responsibility Policy” shall be effective immediately upon approval by the Board and 
                        will supersede and replace the existing policy. As per the provisions of the Act, the company shall give 
                        preference to the local area and areas around it where it operates, for spending the amount earmarked 
                        for Corporate Social Responsibility activities.
                    </p>
                    <p className='name mb-5'>STATUTORY PROVISIONS</p>
                    <p className='pointss mb-5 text-justify'>The provisions of Section 135 of the Act read with the Companies CSR Rules as, amended, require every 
                    company: </p>
                    <ul className='fairlow'>
                        <li className='mb-5'>
                           having net worth of rupees five hundred crore or more, or
                        </li>
                        <li className='mb-5'>
                           turnover of rupees one thousand crore or more or
                        </li>
                        <li className='mb-5'>
                          a net profit of rupees five crore or more 
                        </li>
                    </ul>
                    <p className='mb-5 text-justify'>during the immediately preceding financial year to undertake Corporate Social Responsibility (CSR) 
                    activities as specified under schedule VII of the Act.</p>
                    <p lassName='mb-5 text-justify'>As per the provisions of Section 135(5) the company shall spend, in every financial year, <b>at least two 
                    percent of the average net profits of the company </b> made during the three immediately preceding 
                    financial years in pursuance of its Corporate Social Responsibility Policy.</p>
                    <br/>
                    <p className='name mb-5'>GOVERNANCE FRAMEWORK</p>
                    <p className='ponitss mb-5 text-justify'>IARC’s Board will constitute the Corporate Social Responsibility Committee (CSR Committee) in 
                    accordance with the Act and CSR Rules. The functions of the CSR committee shall be: </p>
                    <ul className='list-disc text-justify'>
                        <li className='mb-5 '>
                        formulate and recommend to the Board, a CSR Policy which shall indicate the activities to be 
                        undertaken by the company in areas or subjects specified in Schedule VII of the Act;
                        </li>
                        <li className='mb-5 '>
                        recommend the amount of expenditure to be incurred on the activities referred to in the 
                        clause above; and 
                        </li>
                        <li className='mb-5 '>
                        monitor the Corporate Social Responsibility Policy of the company from time to time
                        </li>
                        <li className='mb-5 '>
                        Any other matter/thing as may be considered expedient by the members/Board in furtherance 
                        of and to comply with the CSR Policy of the Company and/or specified under the Act and the 
                        Rules. 
                        </li>
                    </ul>
                    <p className='name mb-5'>CSR BUDGET</p>
                    <p className='pointss mb-5 text-justify'>The overall amount to be committed to CSR will be approved by the Board on recommendation of CSR 
                    Committee. In the financial year in which Section 135 of the Act is applicable, IARC shall spend an 
                    amount not less than two percent of its average net profit for the preceding three years, on CSR 
                    activities to be undertaken by the company as specified in Schedule VII of the Act as approved by the 
                    Board. For this purpose, the average net profit will be calculated in accordance with the provisions of 
                    Section 198 of the Act or otherwise as stipulated under the Act and/or the related/amended rules from 
                    time to time.
                    </p>
                    <p className='name mb-5'>AREAS OF CSR INTERVENTION </p>
                    <div className='pointss'>
                        <p className='mb-5 text-justify'>The IARC plans to undertake in all the activities pursuant to the schedule VII, mainly in the areas 
                        mentioned below: </p>
                        <ul className='list-disc'>
                            <li className='mb-5'>Empowering the less fortunate through Sustainable Livelihood Initiatives </li>
                            <li className='mb-5'> Financial Literacy and Inclusion, Skill Training and Livelihood Enhancement</li>
                            <li className='mb-5'> Promoting Education & Healthcare</li>
                            <li className='mb-5'>Environmental Sustainability</li>
                            <li className='mb-5'>Rural Development</li>
                        </ul>
                        <p className='mb-5 text-justify'>As per Rule 4 of (The Companies Corporate Social Responsibility Policy Rules, 2014) of Section 135 of 
                        the Act and the CSR Rules, below mentioned projects/programs/activities shall not be considered as 
                        CSR activities undertaken under Schedule VII of the Act: </p>
                        <ul className='list-disc'>
                            <li className='mb-5'>
                            Projects, programs, or activities that benefit only the employees of IARC or their families
                            </li>
                            <li className='mb-5'>
                            Activities undertaken in pursuance of normal course of business of IARC
                            </li>
                            <li className='mb-5'>
                            Projects, programs, or activities undertaken outside India 
                            </li>
                            <li className='mb-5'>
                            Contribution of any amount directly or indirectly to any political party 
                            </li>
                        </ul>
                        <p className='mb-5 text-justify'>
                        While approving any CSR project or programme, the Board of Directors of the Company shall 
                        determine, whether it will be an Ongoing Project or otherwise. Further while approving any CSR Project 
                        or programmer which will be treated as an Ongoing Project, the Board shall lay down the following: 
                        </p>
                        <ul className=''>
                            <li className='mb-5'>- Estimated budget outlay on CSR project or programme during the course of its implementation </li>
                            <li className='mb-5'>- Estimated budget outlay during every financial year. </li>
                            <li className='mb-5'>- Manner of implementation</li>
                        </ul>
                        <p className='mb-5 text-justify'>In case of multiple Ongoing Projects, Unspent CSR Account shall be opened for each project separately 
                        for better implementation. </p>
                        <p className='mb-5 text-justify'>
                        In case of Ongoing Projects or otherwise, where during any financial year, the budgeted expenditure 
                        for that financial year is not spent fully as a result of which CSR obligation for that financial year is not 
                        fully met, then the unspent amount will be treated in accordance with provisions of the Act. 
                        </p>
                    </div>
                    <p className='name mb-5'>
                    Planning and Implementation Partners
                    </p>
                    <div className='pointss text-justify'>
                        <p className='mb-5'>CSR Committee in consultation with the Board of Directors of the Company will identify suitable 
                        projects for implementation in line with the objects of the Company and requirements laid down under 
                        the Act. </p>
                        <p className='mb-5'>
                        These projects would be executed either directly by the Company and/or through CSR Partner(s)/ CSR 
                        Implementation Agency (ies) as permitted under the Rule 4(1) and Rule 4(1) of the Rules. 
                        </p>
                        <p className='mb-5'>
                        The CSR Committee shall lay out the manner in which external implementing agencies will be selected 
                        for undertaking CSR projects or programmes. 
                        </p>
                    </div>
                    <p className='name mb-5'>Impact Assessment</p>
                    <div className='pointss text-justify'>
                        <p className='mb-5 '>In case the Company’s average CSR expenditure obligation is ten crore rupees or more, in the three 
                        immediately preceding financial years, it shall undertake impact assessment, through an independent 
                        agency, of their CSR projects having outlays of one crore rupees or more, and which have completed 
                        not less than one year. The Company may also voluntarily decide to undertake impact assessment of 
                        its CSR project in order to gauge the real benefits reaped by the beneficiaries of its projects. </p>
                        <p className='mb-5 '> 
                        The impact assessment reports shall be placed before the Board and shall be annexed to the annual 
                        report on CSR. 
                        </p>
                        <p className='mb-5 '>
                        Any expenditure incurred on impact assessment may also be booked towards Corporate Social 
                        Responsibility for that financial year, which shall not exceed five percent of the total CSR expenditure 
                        for that financial year or fifty lakh rupees, whichever is less. 
                        </p>
                    </div>
                    <p className='name mb-5'>MONITORING OF FULFILMENT OF CSR </p>
                    <div className='pointss text-justify'>
                        <p className='mb-5'>
                        The Company Secretary shall be the Compliance Officer for ensuring compliance with requirements of 
                        Section 135 of the Act. He/she shall submit an annual report as at the end of March to the CSR 
                        Committee/Board, incorporating the following: 
                        </p>
                        <ul className='list-disc'>
                            <li className='mb-5'>CSR expenditure approved for the relevant financial year</li>
                            <li className='mb-5'>CSR expenditure actually incurred during the relevant financial year</li>
                            <li className='mb-5'>Status report, project-wise for the expenditure approved vs incurred
                            </li>
                            <li className='mb-5'>
                                Plan for the remaining part of the financial year
                            </li>
                            <li className='mb-5'>
                                Issues, if any
                            </li>
                        </ul>
                        <p className='mb-5'>
                        The report shall also include recommendation for the amount to be committed to CSR for the ensuing 
                        year (if eligible) and the manner of incurring the expenditure
                        </p>
                    </div>
                    <p className='name mb-5'>REPORTING & DISCLOSURE</p>
                    <p className='pointss mb-5 text-justify'>Significant CSR activities and achievements will be reported as part of the Director’s Report in the 
                    IARC’s Annual Report and also as per any other statutory and regulatory reporting requirements. </p>
                    <p className='mb-5'>
                    The CSR Policy of IARC shall be placed on the website of the Company as per Section 135 (4) (a) of the 
                    Act. 
                    </p>
                    <p className='mb-5'>
                    The CSR Policy shall be placed on IARC’s website. 
                    </p>
                    <p className='name mb-5'>REVIEW</p>
                    <div className='text-justify'>
                        <p className='pointss mb-5'>
                        The CSR Policy shall be placed for review by the Board every two years, or as may be required from 
                        time to time in view of statutory changes. 
                        </p>
                    </div>
                    <p className='name mb-5'>COMPOSITION OF THE CSR COMMITTEE</p>
                    <p className='name mb-5'>Existing Members</p>
                    <p className='pointss mb-5'>1. Mr. Harjit Singh Bhatia – Independent Director (Chairman)</p>
                    <p className='pointss mb-5'>2. Mr. Neeraj Inder Mohan – MD &amp; CEO (Member)</p>
                    <p className='pointss mb-5'>3. Mr. Naveen Jain – Nominee Director – Florintree Evolution Private Limited (Member)</p>
                </div>
            </section>
        </>
    )
}
export default Csr