import './App.css';
import { Link } from 'react-router-dom'


const Coming = () => {

    return (
        <>
            <div className='h-20'></div>
            <section>
                <div className='container px-6 xl:px-0 xl:w-4/5 2xl:container mx-auto py-20 min-h-screen flex items-center'>
                    <div className='grid grid-cols-12 gap-y-16 md:gap-x-10 lg:gap-x-12 xl:gap-x-20'>
                        <div className='col-span-12 md:col-span-6 com flex justify-center items-center h-full'>
                            <img src='/assets/coming-soon.png' className='w-full h-auto' />
                        </div>
                        <div className='col-span-12 md:col-span-6 h-full flex flex-col justify-center'>
                            <p className='text-xl mb-6'>COMING SOON</p>
                            <p className='text-[48px] md:text-[56px] xl:text-[60px] font-semibold mb-10 leading-tight'>
                                Our Resolution<br/> Portal is<br/> currently under<br/> development
                            </p>
                            <Link to="/">
                                <button className="goto-home-btn">
                                    Go to Home
                                </button>
                            </Link>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default Coming