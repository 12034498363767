import './App.css';


const Egm_22july_2021 = () => {

    return (
        <>
            <div className='h-20'></div>
            <div className='heading-rounded m-auto text-center text-white font-bold py-4 w-11/12 md:w-3/5'> EGM 22th July 2021</div>
            <div className='container px-6 xl:px-0 xl:w-4/5 2xl:container mx-auto py-20'>
                <div className="fw-col-xs-12 ">
                    <div className="text_block">
                        <p className='text-xl mt-10'>
                            NOTICE is hereby given that an <strong>Extraordinary General Meeting (“EGM”)</strong> of the Members of International Asset Reconstruction Company Private Limited (“IARC”) will be held on <strong> Thursday, 22nd July 2021 at 4 P.M.</strong> (IST) through Video Conferencing (‘VC’)/Other Audio Visual Means (‘OAVM’) facility to transact the following:
                        </p>

                        <p className='agm mt-10'>
                            SPECIAL BUSINESS
                        </p>

                        <p className='agm mt-10'>
                            <strong>TO APPROVE AMENDMENT TO THE ARTICLES OF ASSOCIATION (“AoA”) OF THE COMPANY.</strong>
                        </p>

                        <p className='agm_27_2021_li mt-5'>
                            To consider and if thought fit, pass the following resolution, with or without modification, as a <strong>Special Resolution</strong>:
                        </p>

                        <p className='agm_27_2021_li'>
                            “<strong>RESOLVED THAT</strong> pursuant to the provisions of Section 14 and other applicable provisions, if any, of Companies Act, 2013, (including any statutory modifications or re-enactment thereof, for the time being in force), and the rules framed there under, subject to the approval of the Registrar of Companies, the approval of the Members of the Company be and is hereby accorded for altering the Article 10.1(o)(i) of Articles of Association (“AoA”) by adding the following proviso at the end of Article 10.1(o)(i):
                        </p>

                        <p className='agm_27_2021_li'>
                            Provided that, in case where the Investor has nominated any Investor Director for appointment on the Board of Company, but approval of their appointment is awaited from<br />regulatory agencies including the Reserve Bank of India (RBI), then the quorum of the meetings of the Board and various Committee(s) of the Company (including any Adjourned Meeting) shall be valid with mandatory presence of at least 1 (one) Nominee of Investor in the said Board or committee meetings, as an “Observer” and agenda items, including text of the relevant resolutions, shall be placed before the Board or committee(s) (including in an Adjourned Meeting) with prior consent of the Investor in writing (acting directly or through the Investor nominee). Further, the Company shall give a notice of any meeting of the Board or committee to the Investor in the manner provided in Article 10.1(n), at all times, at least 7 (seven) days prior to the relevant meeting.
                        </p>

                        <p className='agm_27_2021_li'>
                            “<strong>RESOLVED FURTHER THAT</strong> any directors of the Company or Company Secretary be and is hereby authorized to do all such acts and deeds, including filing of relevant forms with MCA, as may be deemed necessary to give effect to the above resolution.”

                        </p>


                    </div>
                    <div className='mt-10'>
                        <p className='mb-5'>
                            <strong>AMENDMENT TO ARTICLES OF ASSOCIATION (“AoA”) OF THE COMPANY.</strong>
                        </p>


                        <p className='agm_27_2021_li'>
                            Until such time the appointment of the directors nominated by Blackstone India ARC SG FDI Holding (NQ) Co. Pte. Ltd. on the Board of the Company is approved by the Reserve Bank of India, to avoid delays in decision making and for smooth functioning of the Company, it is deemed expedient to pass a resolution to alter the AoA of the Company by amending Article 10.1(o)(i). The passing of such resolution will make possible the conduct of meetings of the Board and Committees even in absence of any Investor Directors but with due prior approvals in respect of all agenda items including resolutions by the Investor in writing and with a mandatory presence of at least 1 (one) Nominee of Investor at the said Board or committee meetings as an “Observer”.

                        </p>

                        <p className='agm_27_2021_li'>
                            The Board recommends the resolution No. 1 specified in the Notice as Special Resolution to the Members of the Company for their approval.
                        </p>

                        <p className='agm_27_2021_li'>
                            A copy of the Articles of Association of the Company along with the altered Article will be open for inspection by Members at the Company’s Registered Office between 11.00 A.M. and 1:00 P.M. on any working day of the Company, (Monday to Friday) upto the date previous to the date of the Extraordinary General Meeting.

                        </p>


                        <p className='agm_27_2021_li'>
                            None of the Directors and/or Key Managerial Personnel of the Company and/or their relatives, are concerned or interested financially or otherwise in the resolution set out at Item No. 1 of the Notice.

                        </p>

                    </div>
                    <div className="text_block">
                        <p>
                            <strong>NOTES:</strong>

                        </p>

                        <ol className='list-decimal mt-2 pl-5 mb-14'>
                            <li className='agm_27_2021_li'>
                                An Explanatory Statement pursuant to Section 102 of the Companies Act, 2013, (‘the Act’) relating to the Special Business to be transacted at the Extraordinary General Meeting (‘EGM’) is annexed hereto. The Board of Directors have considered and decided to include the Item Nos. 1 given above as Special Business in the EGM, as they are unavoidable in nature.
                            </li>

                            <li className='agm_27_2021_li'>
                                The Ministry of Corporate Affairs (MCA), in continuation to this Ministry's General Circulars No. 14/2020 dated 8th April 2020, No.17/2020 dated 13th April 2020, No.22/2020 dated 15th June 2020, No.33/2020 dated 28th September 2020, No. 39/2020 dated 31st December 2020 and recent circular No. 10/2021 dated 23rd June 2021, after due examination, decided to allow companies to conduct their EGMs through VC or OAVM or transact items through postal ballot in accordance with the framework provided in the aforesaid Circulars upto 31st December 2021. In accordance with, the said circulars of MCA and applicable provisions of the Act, the EGM of the Company shall be conducted through VC / OAVM.
                            </li>

                            <li className='agm_27_2021_li'>
                                As the EGM shall be conducted through VC / OAVM, the facility for appointment of Proxy by the Members is not available for this EGM and hence the Proxy Form and Attendance Slip including Route Map are not annexed to this Notice.
                            </li>

                            <li className='agm_27_2021_li'>
                                Members are requested to note the designated email address <a href="mailto:iarc@iarc.co">iarc@iarc.co</a>.in for the conveying the vote, where a poll is required during the meeting on any resolution.
                            </li>

                            <li className='agm_27_2021_li'>
                                Institutional / Corporate Members are requested to send a scanned copy (PDF / JPEG format) of the Board Resolution authorising its representatives to attend and vote at the EGM,<br />pursuant to Section 113 of the Act, at <a href="mailto:iarc@iarc.co.in">iarc@iarc.co.in</a>.
                            </li>

                            <li className='agm_27_2021_li'>
                                Members attending the EGM through VC / OAVM shall be counted for the purpose of reckoning the quorum under Section 103 of the Act.
                            </li>

                            <li className='agm_27_2021_li'>
                                The Company’s Registrar and Share Transfer Agent for its share registry (physical and electronic) are Link Intime India Private Limited having their Registered Office at 247 Park, Tower-C, 101, 1st Floor, L.B.S. Marg, Vikhroli (W), Mumbai-400 083.

                            </li>
                        </ol>


                    </div>
                </div>
            </div>
        </>
    );
}

export default Egm_22july_2021;