import BODLayout from './bodLayout';


const Bodvik = () => {

    return (
        <BODLayout
            name='Mr. Vikas Nanda'
            about={
                <>
                    Mr. Vikas Nanda has rich experience in corporate and MSME lending across sectors, and in managing business and operations while upholding principles of corporate governance. He has held senior management positions in State Bank of India and in foreign banks, including as Corporate Banking Head for Bank of America and American Express in India. He has helped build 3 financial services businesses from the ground up as MD/ CEO in a period spanning 15 years. He was the MD & CEO of Bibby Financial Services India, a business he established in 2007 in partnership with Bibby Line Group, UK, and managed it till 2017. During this period, he was also Director of Asia Pacific Board of Bibby Financial Services and assisted in managing greenfield investments in Hong Kong and Singapore, and in the oversight and subsequent sale of its Australia subsidiary. Mr. Nanda advises various small and medium enterprises in successfully upscaling and transforming their businesses.
                </>
            }
            img='/assets/Group 1081101.png'
            designation='Independent Director'
        />
    )
}
export default Bodvik