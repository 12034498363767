import { useLocation } from 'react-router-dom';
import './App.css';
import parse from 'html-react-parser';

export default function JobDescription() {
	const { state } = useLocation();
	const job = state?.job;

	if (!job) {
		return <p>Job not found!</p>;
	}

	return (
		<>
			<section>
				<div className='h-20'></div>
				<div className='heading-rounded m-auto text-center text-white font-bold py-4 w-11/12 md:w-3/5'>
					{job.title}
				</div>
				<div className='container px-6 xl:px-0 xl:w-4/5 2xl:container mx-auto py-20'>
					<p>{job.overview}</p>

					<div className='my-16'>
						<p className='agm mt-10'>Job Description:</p>
						<div className='ml-10 my-4 bullet'>
							{parse(parse(job?.description))}
						</div>

						<p className='agm mt-10'>Requirements:</p>
						<div className='ml-10 my-4 bullet'>
							{parse(parse(job.requirements))}
						</div>
					</div>
				</div>
			</section>
		</>
	);
}
