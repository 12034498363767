import { Link } from 'react-router-dom';
import './App.css';


const Terms = () => {

    return (
        <>
            <div className='h-20'></div>
            <section>
                <div className='heading-rounded m-auto text-center text-white font-bold py-4 w-11/12 md:w-3/5'>Terms And Conditions</div>
                <div className='container px-6 xl:px-0 xl:w-4/5 2xl:container mx-auto py-20'>
                    <p className='points mb-10'>Date: 27th March, 2023</p>
                    <p className='points mb-10'>These Terms and Conditions (Terms) govern the “User's(s)” (as defined below) access to and use of the Portal (as defined below) or website available at – www.iarc.co.in (Website) being offered by International Asset Reconstruction Company Private Limited (IARC). IARC has its principal place of business at Mumbai.</p>
                    <p className='points mb-10'>For the purposes of this Terms, 'User' or 'you' shall mean any person or entity accessing or using the Portal.</p>
                    <p className='name mb-4'>1. INTRODUCTION</p>
                    <div>
                        <p className='points mb-4'>1.1 Please read the Terms carefully before using and accessing the Portal or Website. By accessing or using the Website or Portal or availing any Services you:</p>
                        <div className='ml-6 mb-10'>
                            <p>(a) acknowledge that you have read, understood and accept these Terms and the privacy policy available on the Portal or the Website <Link to='/privacy' className='font-bold'>Privacy Policy</Link></p>
                            <p>(b) represent that you are 18 years or above and are otherwise legally competent to enter into a binding agreement;</p>
                            <p>(c) accept these Terms and agree that they, together with the Privacy Policy, constitute a binding and enforceable contract between IARC and you; and</p>
                            <p>(d) that IARC has not compelled you to accept these Terms and Privacy Policy or avail the Portal or any Services (defined below) and IARC is not responsible for your access to and use of the Portal or Website.</p>
                        </div>
                        <p className='points mb-10'>1.2 The Terms are subject to revision at any time, as determined by IARC, and all changes are effective immediately upon being posted on the Portal or Website (as the case may be). We will notify You of any changes by posting the new Terms on this page and communicating to your registered mobile number and verified email/SMS, however You are advised to review the Terms periodically for any changes. Changes to the Terms are effective as of the date specified at the top of this page.</p>
                    </div>
                    <p className='name mb-4'>2. PORTAL</p>
                    <p className='points mb-10'>Subject to these Terms and Privacy Policy and other relevant terms communicated to you by IARC, IARC provides you with a portal called Resolution Portal (Portal) for management of loans transferred to IARC by the original lender, wherein the User can access their account statement and summary, manage payment cycles, seek settlement and/or restructuring of the loan, make payments and avail other related services as provided by IARC from time to time (Services). You agree to not use the Portal or Services for any purpose that is prohibited by these Terms or applicable law.  </p>
                    <p className='name mb-4'>3. COLLECTED INFORMATION</p>
                    <div>
                        <p className='points mb-4'>3.1 IARC collects specific types of information about you via the Portal and various other means (Collected Information). Such Collected Information is only collected and used in accordance with the Privacy Policy. You expressly consent to IARC's use of your Collected Information.</p>
                        <p className='points mb-10'>3.2 You represent and warrant that to the extent applicable, the Collected Information directly collected from you, is true, complete, accurate in all respects. You should immediately contact IARC at the details provided below if any of the Collected Information, relevant to you, undergoes any change.</p>
                    </div>
                    <p className='name mb-4'>4. DISCLAIMERS</p>
                    <div>
                        <p className='mb-4'>4.1 IARC makes no representation or warranty about the validity, accuracy, correctness, or reliability of any information provided on or through the Portal or Website. IARC hereby disclaims all implied representations, warranties, or guarantees as to the accuracy, validity, reliability or completeness of any such information and material on the Portal or Website. The information available on the Portal or Website are provided for general information purposes only and should not be relied upon or used as the sole basis for making decisions, and any reliance on the same is at the User’s own risk.</p>
                        <p className='mb-10'>4.2 The Portal and Services are provided to you on "as is" and "as available" basis without warranty of any kind either express or implied. IARC does not warrant that the Services provided by the Portal will be uninterrupted or error free or that the Website or Portal or its hosting server are free from viruses or other harmful components. Further, you acknowledge that on occasion the Portal or Website may be temporarily down due to periodic maintenance, technical issues, or for any security reasons beyond IARC's control. In such instances of downtime, IARC shall not be held liable for any losses which you may incur. However, IARC shall make best efforts to rectify the issue causing the downtime and make the Portal or Website available at the earliest.</p>
                    </div>
                    <p className='name mb-4'>5. REGISTRATION</p>
                    <div>
                        <p className='mb-4'>5.1 If you wish to avail the Services, you are required to register/create your own account on the Portal. For the successful registration of your account, you are required to provide IARC with certain information including:</p>
                        <div className='ml-4 mb-4'>
                            <p>(a) Your name;</p>
                            <p>(b) Your registered mobile number (which may be different from that provided to the original lender);</p>
                            <p>(c) Your loan details;</p>
                            <p>(d) Your ID proof like PAN number;</p>
                            <p>(e) Your address (which may be different from that provided to the original lender).</p>
                        </div>
                        <p className='points mb-4'>5.2 Such information will be used and processed in accordance with these Terms and the Privacy Policy. You agree to provide correct and valid information details as required in order to complete the registration process. Upon registration, you will receive a username and password.</p>
                        <p className='points mb-4'>5.3 You will be required to take all the necessary measures for maintaining the privacy of your account’s username and password created upon registration. You will also be responsible for any and all activities that occur through your account. Additionally, you are prohibited from selling, trading, or otherwise transferring your account to another party or impersonating any other person for the purpose of creating an account on the Portal.</p>
                        <p className='points mb-10'>5.4 We encourage you to use "Strong” passwords (i.e., passwords that use a combination of upper and lower case letters, numbers and symbols) for your account. You agree to immediately notify IARC of any unauthorised use of the account or password. You will be solely responsible for your acts on the Portal, including those conducted under the username assigned to you or using your information.</p>
                    </div>
                    <p className='name mb-4'>6. PAYMENTS AND RECOVERY</p>
                    <div>
                        <p className='points mb-4'>6.1 Information regarding the terms and conditions applicable to the various Services, including repayment Services, shall be communicated to you by IARC from time to time.</p>
                        <p className='points mb-4'>6.2 All payments / repayments in respect of the Services through the Portal shall be made to IARC through the Portal or other channels (eg. cheque pick-up) designated by IARC from time to time. To the extent permitted by applicable law and subject to IARC’s Privacy Policy, you acknowledge and agree that IARC may use certain third-party vendors and service providers, including collection agencies or payment gateways, to process payments and manage payment card information.</p>
                        <p>6.3 With respect to digital payments, the various modes available are debit card, netbanking, UPI and popular wallets supported and listed on the Portal. The information on security of these payment methods, along with the information on any fees or charges payable by you will be provided to you by the third-party payment gateway provider. The contact details of the Third-Party payment gateway provider are:</p>
                        <div className='points mb-4 ml-4'>
                            <p>Name: Worldline ePayments India Private Limited</p>
                            <p>Address: Raiaskaran Tech Park,2nd floor of Tower I, Phase II, Sakinaka, M.V. Road, Andheri (East) Mumbai 400072</p>
                            <p>Email id: instantpay.ind@ingenico.com</p>
                        </div>
                        <p>
                            <p className='points mb-4'>6.4 In order to make payments online, You undertake to use a valid payment card or bank details or third-party wallets or details required for any other mode of payment (Payment Details) with the authority to use the same, to have sufficient funds or credit available to complete the payment on the Portal in good standing. These Payment Details will be provided by you to our third-party payment gateway providers and will be governed by such payment gateway providers’ terms and policies. By providing the Payment Details, you represent, warrant, and covenant that:</p>
                            <div className='points mb-4 ml-4'>
                                <p>(a) You are legally authorized to provide such Payment Details;</p>
                                <p>(b) You are legally authorized to perform payments using such Payment Details; and</p>
                                <p>(c) such action does not violate the terms and conditions applicable to your use of such Payment Details or applicable law.</p>
                            </div>
                        </p>
                        <p>
                            <p className='points mb-4'>6.5 Except to the extent otherwise required by applicable law, IARC is not liable for any payments authorized through the Portal using your Payment Details. In particular, IARC will not be liable if:</p>
                            <div className='points mb-4 ml-4'>
                                <p>(a) Your payment card or bank account or third-party wallet does not contain sufficient funds to complete the transaction or the transaction exceeds the credit limit in respect of the Payment Details provided;</p>
                                <p>(b) You have not provided the third-party payment gateway provider with correct Payment Details;</p>
                                <p>(a) Your payment card has expired; or</p>
                                <p>(d) Circumstances beyond IARC’s control (such as, but not limited to, power outages, interruptions of cellular service, or any other interferences from an outside force) prevent the execution of the transaction.</p>
                            </div>
                        </p>
                        <p className='points mb-10'>6.6 The details of recovery agents appointed by IARC are provided <Link to='/privacy' className='font-bold'>here</Link>.</p>
                    </div>
                    <p className='name mb-4'>7. PROPRIETARY RIGHTS</p>
                    <div className='points'>
                        <p className='mb-4'>7.1 The Website, Portal, and all materials on the Website or Portal, including, without limitation, names, logos, trademarks, images, text, graphics, videos, software, and any and all other elements (collectively referred to as “Material”) are the exclusive and sole property of IARC and protected by copyrights, trademarks and/or other intellectual property rights owned and controlled by IARC.</p>
                        <p className='mb-10'>7.2 We provide You with a single limited license to use and access the Portal and Materials hosted as part of the Services, and this license is personal, non-commercial, non-transferable and non-exclusive. You shall not, directly or indirectly, cut, copy, duplicate, decompile, distribute, modify, recreate, reverse engineer, distribute, disseminate, post, publish or create derivative works from, transfer, or sell any content, information, Material, software or any part of the Portal. For the removal of doubt, it is clariﬁed that unlimited or wholesale reproduction, copying of the content for commercial or non-commercial purposes and unwarranted modiﬁcation of data and information within the content of the Portal is not permitted.</p>
                    </div>
                    <p className='name mb-4'>8. THIRD PARTY WEBSITES OR SERVICES</p>
                    <p className='points mb-10'>The Portal or Website may provide or facilitate, or third parties may provide, links or access to other sites, services, and resources (Third Party Services). IARC has no control over such Third Party Services and IARC is not responsible for and does not endorse such Third Party Services. You further acknowledge and agree that IARC will not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any content, events, goods, or services available on or through any Third Party Services. Any dealings you have with third parties while using the Portal are between you and the third party, and you agree that IARC shall not be liable for any loss or claim that you may have against any such third party.</p>
                    <p className='name mb-4'>9. ACCESS AND USE OF THE PORTAL</p>
                    <div>
                        <p className='points mb-4'>9.1 By using the Portal, you agree not to undertake certain activities which include but are not limited to:</p>
                        <div className='ml-4 mb-10'>
                            <p>(a) infringing any proprietary rights, including but not limited to copyrights, patents, trademarks or trade secrets, of any party;</p>
                            <p>(b) except as may be provided hereunder, copying, displaying, distributing, modifying, publishing, reproducing, storing, transmitting, posting, translating, creating any derivative works, renting, or licensing the Portal or any portion thereof;</p>
                            <p>(c) downloading or copying any kind of information for the benefit of another individual, vendor or any other third party;</p>
                            <p>(d) uploading, posting, or transmitting any information through the Portal you do not have a right to make available (such as the intellectual property of another party);</p>
                            <p>(e) uploading, posting, or transmitting any material that contains software or any virus or any other computer code, files or programmes designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment; </p>
                            <p>(f) undertaking any action that imposes or may impose, in IARC's sole discretion, an unreasonable or disproportionately large load on IARC's infrastructure;</p>
                            <p>(g) use of data mining, robots, or similar data gathering and extraction tools;</p>
                            <p>(h) making any back-up or archival copies of the Portal or any part thereof;</p>
                            <p>(i) bypassing any measures used by IARC to prevent or restrict access to the Portal;</p>
                            <p>(j) accessing, monitoring or copying any information on the Portal using any robot, spider, scraper, or other automated means of any manual process for any purpose without IARC’s express written permission;</p>
                            <p>(k) deep-linking to any portion of the Portal for any purpose without the IARC's express written permission; or</p>
                            <p>(l) 'framing', 'mirroring', or otherwise incorporating any part of the Portal into any other website without IARC's prior written authorisation.</p>
                        </div>
                    </div>
                    <p className='name mb-4'>10. INDEMNITY AND RELEASE</p>
                    <p className='points mb-10'>You agree to release, indemnify, and hold IARC and its affiliates and its officers, employees, directors and agents harmless from any and all losses, damages, expenses, including reasonable attorneys' fees, rights, claims, actions of any kind and injury arising out of or relating to these Terms or your use of the Portal.</p>
                    <p className='name mb-4'>11. LIMITATION OF LIABILITY</p>
                    <ol className='points mb-10 list-decimal'>
                        <p>To the fullest extent permitted by applicable law, you hereby expressly agree to defend, indemnify, and hold harmless IARC, its affiliates, officers, agents, and successors and assigns, against any and all claims, liabilities, damages, losses, costs and expenses, including reasonable attorney's fees, caused by or arising out of claims based upon any breach by you of these Terms or applicable law.</p>
                        <p>In no event shall IARC be liable to compensate you or any third party for any direct, indirect, special, incidental, consequential, or punitive damages whatsoever, including those resulting from loss of use, data or profits, whether or not foreseeable, and whether or not you have been advised of the possibility of such damages, based on any theory of liability, including breach of contract or warranty, negligence, or other tortious action, or any other claim arising out of or in connection with your use of or access to the Portal.</p>
                    </ol>
                    <p className='name mb-4'>12. TERMINATION</p>
                    <ol className='points mb-10 list-decimal'>
                        <p>You agree that IARC may, in its sole discretion, suspend or terminate your use of the Portal and your account for any reason, including without limitation, if IARC believes that you have violated or acted inconsistently with the letter or spirit of these Terms. IARC may also in its sole discretion and at any time discontinue the provision of the Portal, or any part thereof, with or without notice. You agree that IARC will not be liable to you or any third party for termination of your access to the Portal.</p>
                        <p>Upon termination, these Terms shall terminate, except for those clauses that are intended to survive expiry or termination.</p>
                    </ol>
                    <p className='name mb-4'>13. NO WAIVER AND SEVERABILITY</p>
                    <p className='points mb-10'>You agree that no action of IARC, other than an express written waiver or amendment, may be interpreted as a waiver or amendment of any of these Terms. In the event any clause of these Terms is found to be unenforceable, then whenever possible, this will not affect any other clause, and each shall remain in full force and effect.</p>
                    <p className='name mb-4'>14. GOVERNING LAW AND DISPUTE RESOLUTION</p>
                    <p className='points mb-10'>These Terms will be governed by and construed in accordance with the laws applicable in India and any dispute arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the applicable court under the jurisdiction of Mumbai.</p>
                    <p className='name mb-4'>15. CONTACT</p>
                    <p className='points mb-4'>You may communicate with the grievance officer for any grievance that you experience in connection with the Portal or Website, at –</p>
                    <p>Name: Mr. Darayus Parvez Bajan</p>
                    <p>Email: grievanceofficer@iarc.co.in</p>
                    <p className='points mb-4'>Address: International Asset Reconstruction Company Private Limited A-601/602/605, 6th Floor, 215 Atrium, Kanakia Spaces, Andheri Kurla Road, Andheri (East), Mumbai- 400 093</p>
                    <p>A ticket number shall be allocated to your complaint through which you can track the status of your compliant.</p>
                </div>
            </section >
        </>
    )
}
export default Terms;