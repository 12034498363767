import AOS from 'aos';
import { useEffect } from 'react';
import './App.css';

function NoticeBox({ heading, text, date, link, delay, pdfFileOne, pdfFileTwo, visibilityDate }) {
    // Enter Visibility Date in format of MM/DD/YYY
    const lastDate = new Date(visibilityDate);
    lastDate.setDate(lastDate.getDate() + 3);

    const currentDate = new Date();

    if(lastDate < currentDate) {
        return;
    }

    const openPDFsInNewTabs = (e, pdf1, pdf2) => {
        e.preventDefault();
      
        pdf1 && window.open(pdf1, '_blank', 'noopener,noreferrer');
        pdf2 && window.open(pdf2, '_blank', 'noopener,noreferrer');
      };

    return (
        <a href={link} onClick={(e) => !link && openPDFsInNewTabs(e, pdfFileOne, pdfFileTwo)} target='_blank' rel='noreferrer noopener' className='flex justify-center h-full' data-aos='fade-up' data-aos-delay={delay}>
            <div className='notice-box h-full overflow-hidden flex flex-col'>
                <div className='assets flex items-center'>
                    <p className='m-auto font-bold text-xl heading text-center'>{heading}</p>
                </div>
                <div className='info-box h-full w-full grow px-4'>
                    <p className='notice font-bold text-center'>{text}</p>
                </div>
                <div className='info-box flex flex-col items-center p-10'>
                    <p className='date font-bold text-center mb-8'>{date}</p>
                    <img src='/assets/EAuction.svg' alt='IARC' style={{ width: "115px", height: '115px' }} />
                </div>
            </div>
        </a>
    );
}

const Asset = () => {

    useEffect(() => {
        AOS.init({
            duration: 1000
        });
    }, []);


    return (
        <>
            <div className='h-20'></div>
            <div className='heading-rounded m-auto text-center text-white font-bold py-4 w-11/12 md:w-3/5'>
                Assets For Sale
            </div>
            <div className='container px-6 xl:px-0 xl:w-4/5 2xl:container mx-auto py-20 min-h-screen'>
                {/* Enter Visibility Date in format of MM/DD/YYY */}
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mb-10 gap-6'>
                     {/* <NoticeBox
                        text='Date of Auction – 30th July 2024'
                        link=''
                        heading='Sale Notice – Jayant Vitamins Ltd. (Ratlam, M.P)'
                        date='EMD submission : 30th July 2024 before 4.00 pm'
                        delay='0'
                        pdfFileOne='/assets/pdf/Jayant_Vitamin_Sale Notice_DRT Auction 30.07.24.pdf'
                        visibilityDate='09/30/2024'
                    /> */}
                     <NoticeBox
                        text='Date of Auction – 25th September 2024'
                        link=''
                        heading='Sale Proclamation of Esvee Express Logistics Limited.'
                        date='EMD submission : 23th September 2024 before 3.00 pm'
                        delay='0'
                        pdfFileOne='/assets/pdf/Sale Proclamation-Date Of Auction 25.09.2024.pdf'
                        visibilityDate='09/26/2024'
                    />
                     <NoticeBox
                        text='Date of Auction – 27th September 2024'
                        link=''
                        heading='Sale Notice – Jayant Vitamins Ltd. (Ratlam, M.P)'
                        date='EMD submission : 27th September 2024 before 4.00 pm'
                        delay='0'
                        pdfFileOne='/assets/pdf/Jayant_vitamins_Sale Notice_Auction Dt 27.09.24.pdf'
                        visibilityDate='09/28/2024'
                    />
                </div> 
            </div>
        </>
    );
}

export default Asset;