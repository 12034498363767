import BODLayout from './bodLayout';


const Bodjim = () => {

    return (
        <BODLayout
            name='Mr. Jimmy Tata'
            about={
                <>
                    Mr. Jimmy Tata is HDFC Bank’s Chief Credit Officer. He holds a Master’s degree in Financial Management from the Jamnalal Bajaj Institute of Management Studies at Mumbai, and is a qualified Chartered Financial Analyst with the Institute of Chartered Financial Analysts in Hyderabad.
                    <br /><br />
                    Mr. Tata has been with the HDFC Bank since 1994, and has over 30 years of broad experience across financial sectors. Prior to joining the HDFC Bank he worked at Apple Industries Limited in various capacities in their financial services division.
                </>
            }
            img='/assets/Group 1081104jimmy.png'
            designation='Nominee, HDFC Bank Ltd'
        />
    )
}
export default Bodjim