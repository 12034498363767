import './App.css';


const Agm_20june_2022 = () => {

    return (
        <>
            <div className='h-20'></div>
            <div className='heading-rounded m-auto text-center text-white font-bold py-4 w-11/12 md:w-3/5'>AGM 20th June 2022</div>
            <div className='container px-6 xl:px-0 xl:w-4/5 2xl:container mx-auto py-20'>
                <p className='agm mt-10'>
                    Notice is hereby given that the <strong>20th Annual General Meeting (“AGM”)</strong> of the members of International Asset Reconstruction Company Private Limited (“IARC”) will be held on <strong>Thursday, 30th June 2022 at 11:30 A.M</strong>. IST through Video Conferencing (‘VC’)/Other Audio Visual Means (‘OAVM’) facility to transact the following: -
                </p>
                <p className='agm mt-16'>ORDINARY BUSINESS</p>
                <ol className='list-decimal mt-6'>
                    <li className='agm_27_2021_li'>
                        To receive, consider and adopt the Audited Financial Statements of the Company for the financial year ended 31st March 2022, together with the Reports of the Directors’ and Auditors’ Reports thereon.
                        <br></br><br></br>
                        <strong>“RESOLVED THAT</strong> the Audited Financial Statements of the Company for the financial year ended 31st March, 2021, together with the Reports of the Directors and Auditors thereon be and are hereby considered, approved and adopted.”
                    </li>
                    <p className='agm mb-4'>SPECIAL BUSINESS:</p>
                    <li className='agm_27_2021_li'>
                        To consider and if thought fit, pass the following resolution, with or without modification, as an <strong>Ordinary Resolution:</strong> <br>
                        </br> <br></br>
                        <strong>“RESOLVED THAT</strong> pursuant to the provisions of Section 161 of the Companies Act, 2013 read with the Companies (Appointment and Qualification of Directors) Amendment Rules, 2018, and other applicable provisions and rules, if any, of the Companies Act, 2013, as amended from time to time or any other law for the time being in force (including any statutory modification or amendment thereto or re-enactment thereof for the time being in force), Mr. Menes Chee (DIN 09178778), who was appointed as an Additional Director of the Company by the Board of Directors and who holds office up to the date of this Annual General Meeting, being eligible, be and is hereby appointed as a the Director of the Company and in respect of whom the Company has received a notice in writing from a member
                        u/s 160 of the Companies Act 2013 proposing his candidature as a Nominee Director, representing Blackstone India ARC SG FDI (NQ) Co. Pte. Ltd., an institutional shareholder of the Company.”<br></br>
                    </li>
                    <li className='agm_27_2021_li'>
                        To consider and if thought fit, pass the following resolution, with or without modification, as an <strong>Ordinary Resolution:</strong><br></br><br></br>
                        <strong>“RESOLVED THAT</strong>pursuant to the provisions of Section 161 of the Companies Act, 2013 read with the Companies (Appointment and Qualification of Directors) Amendment Rules, 2018, and other applicable provisions and rules, if any, of the Companies Act, 2013, as amended from time to time or any other law for the time being in force (including any statutory modification or amendment thereto or re-enactment thereof for the time being in force), Mr. Himanshu Dodeja (DIN 07624809), who was appointed as an Additional Director of the Company by the Board of Directors and who holds office up to the date of this Annual General Meeting, being eligible, be and is hereby appointed as the Director of the Company and in respect of whom the Company has received a notice in writing from a member
                        u/s 160 of the Companies Act 2013 proposing his candidature as a Nominee Director, representing Blackstone India ARC SG FDI (NQ) Co. Pte. Ltd., an institutional shareholder of the Company.”

                        <br></br> <br></br>In accordance with the provisions of Section 102 of the Companies Act, 2013, the Explanatory Statement, setting out all material facts relating to the Item No. 2 and 3 as mentioned in the notice is placed below:<br></br><br></br>
                        <br></br> <br></br>
                        <strong>Item No. 2:</strong>
                        <br></br><br></br>
                        Pursuant to the Articles of Association of the company and consequent to the approval received from the Reserve Bank of India, Mr. Menes Chee (DIN 09178778), aged 45 years, has been inducted as an Additional Director on the Board of IARC w.e.f. 17th December 2021, representing Blackstone India ARC SG FDI Holding (NQ) Co. Pte. Ltd. He holds office up to the date of the ensuing Annual General Meeting of the Company, pursuant to the provisions of Section 161 of the Companies Act 2013.
                        <br></br><br></br>
                        Mr. Chee is a Senior Managing Director in the Tactical Opportunities Group of The Blackstone Group Inc. He is responsible for sourcing, evaluating, and executing investments in private opportunities and capital markets. Mr. Chee joined Blackstone in 2009, as a Managing Director of GSO Capital Partners, and transferred to Tactical Opportunities in 2012. He has also served on the boards of multiple companies, including Acrisure Holdings, Inc., Aqua Finance, Inc., Finance of America Companies, Inc., BTO LT Hold Pty. Ltd., and Partner Life Limited.
                        <br></br><br></br>
                        Before joining Blackstone, Mr. Chee was a Principal with TPG-Axon Capital, where he invested globally in equity and credit in public markets and private transactions. Prior to that, he was a private equity investment professional with Texas Pacific Group (“TPG”). Previous to TPG, Mr. Chee worked at Credit Suisse First Boston in the Merchant Banking Group and at Donaldson Lufkin & Jenrette in the Leveraged Finance Group.
                        <br></br><br></br>
                        Between 1995-1998, Mr. Menes Chee graduated magna cum laude from the University of Pennsylvania with a B.S. in Economics from the Wharton School and a B.A. from the College, where he was elected to Phi Beta Kappa Mr. Menes Chee does not hold any equity shares in the company.
                        <br></br><br></br>
                        The Board of Directors is of the view that Mr. Menes Chee’s knowledge and experience will be of immense benefit and value to the Company and, therefore, recommends his appointment as a Nominee Director of Blackstone India ARC SG FDI Holding (NQ) Co. Pte. Ltd., to the members as an Ordinary resolution.
                        <br></br><br></br>
                        The members are requested to take note that Mr. Nikhil Goyal is appointed as Alternate Director to Mr. Menes Chee with effect from receipt of RBI approval, dated 17th December 2021.
                        <br></br><br></br>
                        Except Mr. Menes Chee and Mr. Nikhil Goyal, Alternate Director to Mr. Menes Chee, none of the Directors and Key Managerial Personnel of the Company and their relatives are concerned or interested, financial or otherwise, in the resolution set out as Item No. 2.
                        <br></br><br></br>
                        <strong>Item No. 3:</strong>
                        <br></br>
                        Pursuant to the Articles of Association of the company and consequent to the approval received from the Reserve Bank of India, Mr. Himanshu Dodeja (DIN 07624809), aged 41 years, has been inducted as an Additional Director on the Board of IARC w.e.f. 17th December 2021, representing Blackstone India ARC SG FDI Holding (NQ) Co. Pte. Ltd. He holds office up to the date of the ensuing Annual General Meeting of the Company, pursuant to the provisions of Section 161 of the Companies Act 2013.
                        <br></br><br></br>
                        Mr. Dodeja joined Blackstone in 2016 and is currently a Managing Director at Blackstone India. Mr. Dodeja plays a key role in the mergers and acquisitions, private equity, capital markets and special situations related initiatives of the firm and takes a lead on all the legal matters in India. As part of his experience at Blackstone, Mr. Dodeja has also advised on various matters pertaining to finance, securitization, and reconstruction. Mr. Dodeja serves as a director on the board of Blackstone Advisors India Private Limited, which is an advisor to The Blackstone Group Inc. and its affiliated funds. The Blackstone Group Inc. is the world’s largest asset manager with assets under management of US$ 619 billion. He has also served on the boards of multiple companies, including Blackstone Fund Services Private Limited, MTAR Technologies Limited, Gateway Rail Freight Private Ltd, Naman Mall Management Company Private Limited, and Dawn Retail Private Limited.
                        <br></br><br></br>
                        With almost two decades of work experience, he regularly provides strategic advice to Blackstone on acquisition of pre-bankruptcy and stressed assets, and acquisitions undertaken through insolvency processes. Mr. Dodeja recently played a critical role in the acquisition of a troubled asset, the first real estate asset acquisition under the Indian bankruptcy regime by a leading private equity fund. Further, in his position as General Counsel of Blackstone, he regularly advises Blackstone portfolio companies, and has had extensive experience dealing with lenders, various regulators, and government agencies across the country. Mr. Dodeja is also a member of the FICCI Capital Markets Committee and FICCI Committee on Private Equity. Mr. Dodeja has won many awards for his deal making ability and is listed as a top category legal professional in the country.
                        <br></br><br></br>
                        Before joining Blackstone, Mr. Dodeja was a partner at Cyril Amarchand Mangaldas in Mumbai, where he focused on private equity, mergers and acquisitions transactions, special situations, and advised many other marquee financial investors. Mr. Dodeja is licensed to practice in India and in New York.
                        <br></br><br></br>
                        Mr. Dodeja completed his graduate studies in corporate law, from the New York University.
                        <br></br><br></br>
                        Mr. Dodeja does not hold any equity shares in the company.
                        <br></br><br></br>
                        The Board of Directors is of the view that Mr. Dodeja’s knowledge and experience will be of immense benefit and value to the Company and, therefore, recommends his appointment as a Nominee Director of Blackstone India ARC SG FDI Holding (NQ) Co. Pte. Ltd., to the members as an Ordinary resolution.
                        <br></br><br></br>
                        Except Mr. Dodeja, none of the Directors and Key Managerial Personnel of the Company and their relatives are concerned or interested, financial or otherwise, in the resolution set out as Item No. 3.
                        <br></br><br></br>
                    </li>
                </ol>
                <strong>Notes</strong>
                <ol className='list-decimal mt-2 pl-5 mb-14'>
                    <li className='agm_27_2021_li'>
                        An Explanatory Statement pursuant to Section 102 of the Companies Act, 2013, (‘the Act’) relating to the Special Business to be transacted at the Annual General Meeting (‘AGM’) is annexed hereto. The Board of Directors have considered and decided to include the Item No. 2, and 3 listed above as Special Business in the forthcoming AGM.
                    </li>
                    <li className='agm_27_2021_li'>
                        The Ministry of Corporate Affairs (MCA), in continuation to this Ministry's General Circulars No. 14/2020 dated 8th April 2020, No.17/2020 dated 13th April 2020, No.22/2020 dated 15th June 2020, No.33/2020 dated 28th September 2020, No. 39/2020 dated 31st December 2020, circular No. 10/2021 dated 23rd June 2021, and recent 2/2022 dated 5th May 2022 after due examination, decided to allow companies to conduct their EGMs through VC or OAVM or transact items through postal ballot in accordance with the framework provided in the aforesaid Circulars up to 31st December 2022. In accordance with, the said circulars of MCA and applicable provisions of the Act, the AGM of the Company shall be conducted through VC / OAVM.
                    </li>
                    <li className='agm_27_2021_li'>
                        As the AGM shall be conducted through VC / OAVM, the facility for appointment of Proxy by the Members is not available for this AGM and hence the Proxy Form and Attendance Slip including Route Map are not annexed to this Notice.
                    </li>
                    <li className='agm_27_2021_li'>
                        Members are requested to note the designated email address <a href="mailto:iarc@iarc.co.in">iarc@iarc.co.in</a> for the conveying the vote, where a poll is required during the meeting on any resolution.
                    </li>
                    <li className='agm_27_2021_li'>
                        Institutional / Corporate Members are requested to send a scanned copy (PDF / JPEG format) of the Board Resolution authorising its representatives to attend and vote at the AGM, pursuant to Section 113 of the Act, at
                        <a href="mailto:iarc@iarc.co.in"> iarc@iarc.co.in</a>/<a href="mailto:naina.kurane@iarc.co.in">naina.kurane@iarc.co.in</a>.
                    </li>
                    <li className='agm_27_2021_li'>
                        Members attending the AGM through VC / OAVM shall be counted for the purpose of reckoning the quorum under Section 103 of the Act.
                    </li>
                    <li className='agm_27_2021_li'>
                        The Directors’ Report, Auditors’ Report, Audited Financial Statements as at 31st March 2022 are enclosed.
                    </li>
                    <li className='agm_27_2021_li'>
                        The Company’s Registrar and Share Transfer Agent for its share registry (physical and electronic) are Link Intime India Private Limited having their Registered Office at 247 Park, Tower-C, 101, 1st Floor, L.B.S. Marg, Vikhroli (W), Mumbai-400 083.
                    </li>
                </ol>
            </div>
        </>
    );
}

export default Agm_20june_2022;