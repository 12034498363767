import { route } from './route';
import { callAPI } from './api';

const fetchJobs = async () => {
	try {
		const endpoint = `${route.getCareers.endPoint}&limit=500&page=1`;
        const response = await callAPI(route.getCareers, endpoint);
		if (response) {
			if (response.data.success) {
				return { success: response.data.response };
			}
			return { error: response.data.response };
		}
		if (!response.ok) {
			throw new Error('Failed to fetch jobs');
		}
	} catch (error) {
		console.error('Error fetching jobs:', error);
		throw error;
	}
};

export { fetchJobs };
