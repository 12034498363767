import './App.css';


const Default = () => {

    return (
        <>
            <div className='h-20'></div>
            <div className='heading-rounded m-auto text-center text-white font-bold py-4 w-11/12 md:w-3/5'> Wilful Defaulter</div>
            <section>
                <div className='container px-6 xl:px-0 xl:w-4/5 2xl:container mx-auto py-20 min-h-screen'>
                    <div className='wilful'>
                        <p className='name'>List of Wilful Defaulters</p>
                        <p className='mt-10 mb-20 points'>Currently, there are no wilful defaulters as per our records</p>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Default