import './App.css';
import BODLayout from './bodLayout';


const Bodmen = () => {

    return (
        <BODLayout
            name='Mr. Menes Chee'
            about={
                <>
                    Mr. Chee is a Senior Managing Director in the Tactical Opportunities Group of The Blackstone Group, Inc. He is responsible for sourcing, evaluating, and executing investments in private opportunities and capital markets.
                    <br /><br /><br />
                    Mr. Chee joined Blackstone in 2009 as a Managing Director of GSO Capital Partners and later got transferred to Tactical Opportunities in 2012. He has also served on the boards of multiple companies, including Acrisure Holdings, Inc., Aqua Finance, Inc., Finance of America Companies, Inc., BTO LT Hold Pty. Ltd., and Partner Life Limited.
                    <br /><br /><br />
                    Before joining Blackstone, Mr. Chee was a Principal with TPG-Axon Capital, where he invested globally in equity and credit in public markets and private transactions. Prior to that, he was a private equity investment professional with Texas Pacific Group. Between 1995-1998, Mr. Menes Chee graduated magna cum laude from the University of Pennsylvania with a BS in Economics from the Wharton School and a BA from the College, where he was elected to Phi Beta Kappa.
                </>
            }
            img='/assets/Group 1081102menes.png'
            designation='Nominee, Blackstone Group'
        />
    )
}
export default Bodmen