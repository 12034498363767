import BODLayout from './bodLayout';


const Bodnik = () => {

    return (
        <BODLayout
            name='Mr. Nikhil Goyal'
            about={
                <>
                    Based in Mumbai, Mr. Goyal is a Principal at Blackstone Advisors India Private Limited. Since joining Blackstone in 2015, Mr. Nikhil has been involved in the firm’s investment in IARC, Simplilearn, Xpressbees, CMS Infosystems, Multi Commodity Exchange and FINO. Before joining Blackstone, Mr. Nikhil worked at Surveyor Capital in New York, handling long/short equity investments.
                    <br /><br />
                    Mr. Goyal has a bachelor's degree in Technology from the Indian Institute of Technology, Delhi. He holds a master's degree in Business Administration from Wharton School at the University of Pennsylvania, where he graduated as a Palmer Scholar.
                </>
            }
            img='/assets/Group 1081103nik.png'
            designation='Nominee, Blackstone Group'
        />
    )
}
export default Bodnik