import BODLayout from "./bodLayout"

const Bodnee = () => {

    return (
        <BODLayout
            name='Neeraj I. Mohan'
            about={
                <>
                    Mr. Neeraj I. Mohan joined International Asset Reconstruction Company Private Limited (“IARC”) in July 2022 and is currently the Managing Director & Chief Executive Officer of the Company. Over the past two decades, Mr. Mohan has played senior leadership roles in private equity, financial services industry, and consulting
                    <br /><br />
                    Mr. Mohan has worked with EY Parthenon as Partner & Head India and has doubled the target business with white space practices. Mr. Mohan has achieved high growth with focus on knowledge creation and thought leadership, innovative research, and partnerships with knowledge industry leaders.
                    <br /><br />
                    Mr. Mohan has delivered turnarounds across industry type, organization type and ownership structures for NCC Limited, Gateway Rail Freight Limited, Alembic International Business, Ranbaxy API Business. Mr. Mohan have accelerated growth and strategy, organizational changes, and execution of several key projects in Aadhar Housing Finance, Sona Comstar, Aakash Education, and International Tractors Limited.
                    <br /><br />
                    Mr. Mohan has significant experience in managing complexity and stakeholder management, Board management, relationship management with civil society, regulators, government agencies, etc.
                    <br/><br/>
                    Mr. Mohan holds degree of PGDM from IIM Ahmedabad and is also a Chartered Accountant from The Institute of Chartered Accountant of India, in addition to being a Commerce Graduate from Guru Nanak Dev University, Amritsar.
                </>
            }
            img='/assets/Group 1081106neeraj.png'
            designation='Managing Director & Chief Executive Officer'
        />
    )
}
export default Bodnee