import React from 'react';
import { Link } from 'react-router-dom';

const CustomerLoginButton = ({styling}) => {
	return (
		<Link to='https://res.iarc.co.in/login' target='_blank'>
			<button
				type='button'
				className={`${styling} focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 md:mr-0 hidden lg:inline-block`}
			>
				Customer Login
			</button>
		</Link>
	);
};

export default CustomerLoginButton;
