import './App.css';


const Whis = () => {

    return (
        <>
            <div className='h-20'></div>
            <div className='heading-rounded m-auto text-center text-white font-bold py-4 w-11/12 md:w-3/5'> Whistle Blower Policy</div>
            <section>
                <div className='container px-6 xl:px-0 xl:w-4/5 2xl:container mx-auto py-20'>
                    <p className='name pb-10'>
                        Objective
                    </p>
                    <div className='pointss'>
                        <p className='mb-5'>The objective of this policy is to institute a mechanism</p>
                        <ul className='list-decimal'>
                            <li>
                                Which will give all directors and employees of IARC as well as its service providers an opportunity to bring to the notice of IARC's management, actions by any person employed or engaged by IARC, which in their view are prejudicial to IARC's interest, including but not limited to the following categories:
                                <ul className='list-disc'>
                                    <li className='mb-5'>wilful misuse of authority or discretion</li>
                                    <li className='mb-5'>
                                        actions which do not conform to high standards of professionalism and integrity
                                    </li>
                                    <li className='mb-5'>actions which may lead to incorrect financial reporting, are in violation of IARC's policies (including Code of Conduct & Ethics) or contrary to law/regulatory prescriptions</li>
                                    <li className='mb-5'>unlawful conduct</li>
                                    <li className='mb-5'>misconduct with other employees, customers or suppliers</li>
                                    <li className='mb-5'>misrepresentation of facts and information related to IARC that causes breach of trust of customer or any other such third party</li>
                                    <li className='mb-5'>unauthorized use of IARC's funds</li>
                                    <li className='mb-5'>criminal offence such as fraud, theft, corruption</li>
                                    <li className='mb-5'> discrimination against an employee or customer or service provider on the grounds of sex, religion, caste or disability</li>
                                    <li className='mb-5'>any other form of improper action or conduct</li>
                                </ul>
                            </li>
                            <li className='mb-5'>which will give adequate safeguards, against victimization or reprisal, to the person resorting, in good faith, to whistleblowing</li>
                            <li className='mb-5'>Any complaints regarding sexual harassment at the workplace should not be reported to the Ombudsperson. Any complaints of such nature cannot be investigated by the Ombudsperson and would not be taken forward. If you wish to report a concern or incident of sexual harassment at the workplace, please report such concern only to the Internal Committee constituted by the Company, by writing to Ms. Aruna Mannath [aruna@iarc.co.in].</li>
                        </ul>
                        <p className='name mt-10 mb-10'>
                            Who may Whistle-Blow
                        </p>
                        <ul className='list-decimal'>
                            <li className='mb-5'>
                                Any director or any permanent or temporary employee (including part-time and contractual employees) of IARC or retainers or any contractors, vendors, suppliers, or agencies that provide goods or services to IARC or entity engaged by IARC for services relating to acquisition or resolution of financial assets or customers of IARC or shareholders of IARC or any other person having an association with IARC (referred to as "complainant" later in this policy) may lodge a complaint/disclosure that is permitted to be made under this policy<br></br><br></br>(referred to as "disclosure" later in this policy). These definitions are intended to be interpreted in an inclusive manner and not restrictively.
                            </li>
                            <li className='mb-5'>
                                As a corollary to the above clause, it will be expected of every director or employee of IARC, who is in possession of information about any irregular practice in respect of the functioning of the organization, that he or she shall bring such irregular practice to the notice of the management.
                            </li>
                            <li className='mb-5'>While making a disclosure, the complainant should act in good faith and should have direct knowledge of the alleged irregularities or have reasonable grounds to believe that the allegation contained in the disclosure is substantially true.</li>
                        </ul>
                        <p className='name mt-10 mb-10'>Ombudsperson</p>
                        <ul className='list-decimal'>
                            <li className='mb-5'>
                                The MD & CEO of IARC shall be the Ombudsperson in respect of any disclosure against an employee below the rank of President.
                            </li>
                            <li className='mb-5'>
                                The Chairman of the Audit Committee shall be the Ombudsperson in respect of any disclosure against any person not falling in the above category.
                            </li>
                            <li className='mb-5'>
                                In case of doubt, a disclosure may be made to the Chairman of the Audit Committee, whose decision as to which Ombudsperson shall deal with the disclosure shall be final.
                            </li>
                            <li className='mb-5'>
                                In all cases, the complainant will have direct access to the Chairman of the Audit Committee.
                            </li>
                        </ul>
                        <p className='name mt-10 mb-10'>The Process Flow</p>
                        <ul className='list-decimal'>
                            <li className='mb-5'>
                                The disclosure shall be given in writing to the Ombudsperson under the signature of the complainant and will be sent to MD & CEO/Chairman of the Audit Committee (as applicable) at the primary address of IARC or a specific email account if designated for this purpose.
                            </li>
                            <li className='mb-5'>
                                If the complainant is not an employee of IARC, the complaint should clearly mention the name and designation of the complainant and the name of his/her organization and should be addressed to the Chairman of the Audit Committee.
                            </li>
                            <li className='mb-5'>
                                The disclosure should be accompanied by -
                                <ul className='list-disc'>
                                    <li className='mb-5'>
                                        A declaration that the disclosure is made in good faith and that the complainant believes the disclosure to be substantially true;
                                    </li>
                                    <li className='mb-5'>
                                        A statement of the grounds on which the complainant believes that the alleged action is prejudicial to the Company’s interests;
                                    </li>
                                    <li className='mb-5'>
                                        The available documentary evidence in support of the disclosure;
                                    </li>
                                    <li className='mb-5'>
                                        A confirmation that the complainant will cooperate with any investigation in respect of the disclosure and provide further available information if and when called upon to do so.
                                    </li>
                                </ul>
                            </li>
                            <li className='mb-5'>
                                An acknowledgement will be given by the Ombudsperson or by a person under his authority to the complainant.
                            </li>
                            <li className='mb-5'>
                                Anonymous disclosures or disclosures received from persons not covered under this policy may not be pursued by the Ombudsperson. However, the Ombudsperson will have the discretion to make further enquiries and decide on the course of action in such cases.
                            </li>
                            <li className='mb-5'>
                                Within seven working days of the receipt of a disclosure, the Ombudsperson shall examine the same and conclude (after making preliminary enquiries, if necessary) whether the disclosure merits further investigations.
                            </li>
                            <li className='mb-5'>
                                If it is concluded that the disclosure does not merit further investigations, the basis of such conclusion will be documented.
                            </li>
                            <li className='mb-5'>
                                If it is concluded that further investigation is necessary, the Ombudsperson will appoint such person(s) as considered appropriate to conduct such investigations, with a mandate to conclude the investigation within a specified period of time. Extension(s) in the specified period may be granted on request from the investigator(s) based on circumstances.
                            </li>
                            <li className='mb-5'>
                                The person(s) in respect of whom (i.e., against whom) a disclosure is made and/or the persons against whom an investigation is conducted shall cooperate with the investigation. Any attempt to obstruct the process may attract disciplinary action, including and up to termination.
                            </li>
                            <li className='mb-5'>
                                Based on the outcome of investigation, the Ombudsperson will decide on the further course of action in each matter.
                            </li>
                            <li className='mb-5'>
                                If corrective action is deemed necessary, the Ombudsperson will direct the concerned functionary to initiate such action.
                            </li>
                            <li className='mb-5'>
                                If disciplinary proceedings are considered necessary against any employee of IARC, the same will be initiated in compliance with the disciplinary policy or any other policy that may be applicable in this regard to the concerned employee.
                            </li>
                            <li className='mb-5'>
                                The outcome of the enquiry/investigation will be communicated to the complainant, subject to limitations imposed by legal/regulatory restraints or confidentiality clauses.
                            </li>
                            <li className='mb-5'>
                                Whistleblowing will not result in any immunity to the complainant from any complicity/involvement in the alleged irregularities or related activities.
                            </li>
                        </ul>
                        <p className='name mt-10 mb-10'>Safeguards</p>
                        <div className='pointss mb-10'>
                            <ul className='list-decimal'>
                                <li className='mb-5'>
                                    The name of the complainant will not be disclosed by the Ombudsperson, unless required under any provision of law or agreed to in writing by the complainant or unless the complainant has himself/herself disclosed the same to anyone other than the Ombudsperson.
                                </li>
                                <li className='mb-5'>
                                    The contents of any disclosure and the consequent proceedings including any investigation shall be kept confidential, as far as practicable and subject to requirements of rules and procedures.
                                </li>
                                <li className='mb-5'>
                                    Bonafide use of discretion by employees of IARC will not be subjected to investigation under provisions of this policy. The decision of the Ombudsperson in the matter will be final.
                                </li>
                                <li className='mb-5'>
                                    The proceedings will be conducted without any presumption of guilt and in a transparent and objective manner.
                                </li>
                                <li className='mb-5'>
                                    If enquiry/investigation suggests that the disclosure was made with malicious intent or not in conformity with the spirit of this policy, the Ombudsperson shall direct the appropriate authority to initiate action against the complainant.
                                </li>
                                <li className='mb-5'>
                                    No complainant shall be victimized in any manner for the reason of having made such a disclosure. Any instance of such victimization can be brought to the knowledge of the Ombudsperson, who will initiate remedial action if considered necessary.
                                </li>
                                <li className='mb-5'>
                                    Any person who appears as a witness, or otherwise assists an investigation relating to a disclosure, shall be given the same degree of protection as the whistle blower.
                                </li>
                            </ul>
                        </div>
                        <p className='name mb-10 mt-10'>
                            Review and Reporting
                        </p>
                        <div className='pointss mb-10'>
                            <ul className='list-decimal'>
                                <li className='mb-5'>
                                    The Audit Committee of the Board will oversee the implementation of this whistle blower policy. Any member of the Audit Committee, who has a conflict of interest in a given case shall recuse himself/herself, and the other members of the Committee would deal with the matter on hand.
                                </li>
                                <li className='mb-5'>
                                    An annual report of disclosures received, and the outcome of such disclosures will be submitted by the Audit Committee to the appropriate authority in IARC.
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
            </section>
        </>

    )
}

export default Whis
