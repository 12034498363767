import './App.css';


const Recovery = () => {

    return (
        <>
            <div className='h-20'></div>
            <div className='heading-rounded m-auto text-center text-white font-bold py-4 w-11/12 md:w-3/5'>Recovery Agents</div>
            <section>
                <div className='container px-6 xl:px-0 xl:w-4/5 2xl:container mx-auto py-20 min-h-screen flex flex-col justify-center'>
                    <p className='text-2xl'>
                        List of authorised recovery agency(ies)/agent(s), resolution agency(ies)/agent(s), collection agency(ies)/agent(s):
                    </p>
                    <div className='overflow-x-auto w-full max-h-[50rem] overflow-y-scroll mt-16'>
                        <table className="table-auto recovery-agent ">
                            <thead>
                                <tr>
                                    <th className='lg:min-w-[250px] '>Sr.No</th>
                                    <th className='min-w-[250px]'>Name of agency</th>
                                    <th className='min-w-[300px]'>Address</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>Arnav Asset SolutionsPrivate Limited</td>
                                    <td>Gr. Floor, Uma Shankar, Mumbai 27 400099</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>2</td>
                                    <td>HDFC Bank Limited</td>
                                    <td>Peninsula Business Park, Tower A, 16th Flr, Lower Parel</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>ABHI ASSOCIATES</td>
                                    <td>Shop No. 144, Lekhraj Khazana, Faiz Lucknow 09 226016</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>4</td>
                                    <td>ADIXIT & ASSOCIATES</td>
                                    <td>712, GARHAPHATAK, RANITAL LINK ROAD Jabalpur 23 482002</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>AGPO MANAGEMENTSERVICES PVT. LTD</td>
                                    <td>2nd Floor, 111/273 Harsh Nagar Kanpur 09 208001</td>
                                </tr>

                                <tr className='bg-alt'>
                                    <td>6</td>
                                    <td>AKRITI ENTERPRISES</td>
                                    <td>303, Alankar Point, Geeta Bhawan, I Indore 23 452001</td>
                                </tr>
                                <tr>
                                    <td>7</td>
                                    <td>BHUPINDER ASSOCIATES</td>
                                    <td>HJ-582, Bhupinder Associates, Housi Ludhiana 03 141012</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>8</td>
                                    <td>CEDAR BUSINESSSOLUTION</td>
                                    <td>HO: 7/3, 2ND FLOOR , SUBBARAYA STRE Chennai 33 600030</td>
                                </tr>
                                <tr>
                                    <td>9</td>
                                    <td>CHAUDHARY ENTERPRISE</td>
                                    <td>Shop No. 227, Shayona Plaza, Punaga Surat 24 395006</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>10</td>
                                    <td>CLXNS TECHNOLOGIESPRIVATE LIMITED</td>
                                    <td>EFC BUSNIESS CENTRE, 10TH FLOOR, EM Mumbai 27 400708</td>
                                </tr>
                                <tr>
                                    <td>11</td>
                                    <td>CRYSTAL CREDIT RISKMANAGEMENT SERVICESPVT LTD</td>
                                    <td>208, GOWTHAM ARCADE, T.V.SAMY ROAD Coimbatore 33 641002</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>12</td>
                                    <td>DABLA BROTHERS PVT.LTD.</td>
                                    <td>D-232, Kalidas Marg, Bani Park, Jai Jaipur 08 302016</td>
                                </tr>
                                <tr>
                                    <td>13</td>
                                    <td>DAIWIK CREDIT SERVICES</td>
                                    <td>No. 4, 1st Floor, Bharathi Nagar, 4 Chennai 33 600017</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>14</td>
                                    <td>EARTH SERVICES</td>
                                    <td>WZ-12, Kh. No. 2882, 1st Floor, Kai Delhi 07 110015</td>
                                </tr>
                                <tr>
                                    <td>15</td>
                                    <td>ELEVATE BPO SERVICESPVT LTD</td>
                                    <td>17A/41 4TH Floor, Gurudwara Road, K Delhi 07 110005</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>16</td>
                                    <td>EXCELLENT SERVICES</td>
                                    <td>Ground Floor, Plot No. 31A, Hari Vi Delhi 07 110078</td>
                                </tr>
                                <tr>
                                    <td>17</td>
                                    <td>FINANCIAL SOLUTIONS</td>
                                    <td>Main Chowk, Barbar shah Srinagar, J Srinagar 01 190001</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>18</td>
                                    <td>FORTE MANAGEMENTSERVICES</td>
                                    <td>FIRST FLOOR, 22/1 and 22/2, SRI VIG Chennai 33 600015</td>
                                </tr>
                                <tr>
                                    <td>19</td>
                                    <td>HEM DEBT MANAGEMENTTEAM</td>
                                    <td>1549, Office No 10, Indra Towers, S Pune 27 411030</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>20</td>
                                    <td>HITEK SYNDICATE</td>
                                    <td>303, Sarine sonia sadan, Tower G-11 Delhi 07 110018</td>
                                </tr>
                                <tr>
                                    <td>21</td>
                                    <td>HITEK SYNDICATE</td>
                                    <td>2nd Main Road, No.2, United India C Chennai 33 600024</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>22</td>
                                    <td>JAVED AKHTAR AND CO</td>
                                    <td>25/51 Mahatma Gandhi marg, Civil Li Allahabad 09 211001</td>
                                </tr>
                                <tr>
                                    <td>23</td>
                                    <td>KP SERVICES</td>
                                    <td>A/209, 210 Trithraj Complex Near Ha Ahmedabad 24 380006</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>24</td>
                                    <td>MARK AGENCY MUMBAI</td>
                                    <td>7th Floor, 709 Topi wala center, Op Mumbai 27 400062</td>
                                </tr>
                                <tr>
                                    <td>25</td>
                                    <td>MAX FINANCIAL SERVICES</td>
                                    <td>C-109, 11th B Cross West, Thillai N Tiruchirappalli 33 620018</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>26</td>
                                    <td>METICULOUS</td>
                                    <td>A-001, Ground Floor, Amrut Bhavan, Mumbai 27 400028</td>
                                </tr> 
                                <tr>
                                    <td>27</td>
                                    <td>MICRO MANAGEMENTASSOCIATES</td>
                                    <td>17/47, Mall Road, Near Riwaaz garments, Tilak Nagar, New Delhi - 110018</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>28</td>
                                    <td>MONARCH SERVICES</td>
                                    <td>Plot number 32, 2nd floor, Aaryan H Nashik 27 422005</td>
                                </tr>
                                <tr>
                                    <td>29</td>
                                    <td>OM SAI CREDITCOLLECTION SERVICESPVT LTD</td>
                                    <td>209 Anak Apartment, Shiralsheth Chowk, Rasta Peth , Pune - 411011</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>30</td>
                                    <td>PANCHAJANYAASSOCIATES PVT LTD</td>
                                    <td>1st Floor, 1/219 - G, EC Square, Ch Kozhikode 32 673005</td>
                                </tr>
                                <tr>
                                    <td>31</td>
                                    <td>PARITOSH SYSTEMS ANDSOLUTIONS</td>
                                    <td>1,2 Sainath Plaza, J.N.Road, Mulund Mumbai 27 400080</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>32</td>
                                    <td>PERFECT ASSOCIATE</td>
                                    <td>03, Second Floor, Nahar Commerical Ahmedabad 24 380001</td>
                                </tr>
                                <tr>
                                    <td>33</td>
                                    <td>PREET ENTERPRISE</td>
                                    <td>201, Jash Infinity, B/H sub jail, O Surat 24 395002</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>34</td>
                                    <td>PREMIUM CONNECTCENTRE</td>
                                    <td>702, Ashok Heights, Nikolas wadi road Mumbai 27 400069</td>
                                </tr>
                                <tr>
                                    <td>35</td>
                                    <td>R.K ENTERPRISES</td>
                                    <td>C-5, Block C, Sudershan Park, West Delhi 07 110015</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>36</td>
                                    <td>RAJ ENTERPRISES</td>
                                    <td>Shop no. 3/A, Ramdev Chawl, Gulmoha Mumbai 27 400058</td>
                                </tr>
                                <tr>
                                    <td>37</td>
                                    <td>RAWALA CONSULTANCY</td>
                                    <td>Office No. 27, Ground floor Plot no Jaipur 08 302004</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>38</td>
                                    <td>RELIABLE PERFECTSOLUTION</td>
                                    <td>Shop No. 17, Ram Niwas, M G Road, N Thane 27 400602</td>
                                </tr>
                                <tr>
                                    <td>39</td>
                                    <td>RELIABLE SOLUTIONS</td>
                                    <td>4B/22 Ground Floor, Tilak Nagar, Ba Delhi 07 110018</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>40</td>
                                    <td>S D ENTERPRISES</td>
                                    <td>FIRST FLOOR, SC0 53,, SECTOR 47D, C Chandigarh 03 160047</td>
                                </tr>
                                <tr>
                                    <td>41</td>
                                    <td>SAI FINANCIAL & BPOSOLUTIONS</td>
                                    <td>F.NO. CH-01, P.No.59-A, Madhusadan Nagpur 27 440010</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>42</td>
                                    <td>SAIS FINANCIAL SERVICES</td>
                                    <td>5-B, C-28 Royal Industrial Estate, Mumbai 27 400031</td>
                                </tr>
                                <tr>
                                    <td>43</td>
                                    <td>SHAH & JHAVERIASSOCIATES</td>
                                    <td>3, 303, Sears Tower, Gulbai tekra, Ahmedabad 24 380006</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>44</td>
                                    <td>SHREE FINANCE NAGPUR</td>
                                    <td>House Number 588, Hansapuri Mothgha Nagpur 27 440018</td>
                                </tr>
                                <tr>
                                    <td>45</td>
                                    <td>SHREE OMKARASSOCIATE</td>
                                    <td>1-D, THACKER INDUSTRIAL PRIMISES CO Mumbai 27 400011</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>46</td>
                                    <td>SRI VISHNU ASSOCIATES</td>
                                    <td>DOOR NO 27, RAMAKRISHNA STREET, T N Chennai 33 600017</td>
                                </tr>
                                <tr>
                                    <td>47</td>
                                    <td>SRS ADVISORY PVT. LTD</td>
                                    <td>21/1D, Ballygunge Station road, Kolkata 19 700019</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>48</td>
                                    <td>SSS ASSOCIATES</td>
                                    <td>1st Floor, 37 Gayathri complex, Opp Kanyakumari 33 629001</td>
                                </tr>
                                <tr>
                                    <td>49</td>
                                    <td>SV ENTERPRISES</td>
                                    <td>94E, Swami Bhawani, Kamgar Nagar, S Mumbai 27 400024</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>50</td>
                                    <td>SWARA ASSOCIATES</td>
                                    <td>Flat no. E-604, Sun Fantasy, S.No. Pune 27 411046</td>
                                </tr>
                                <tr>
                                    <td>51</td>
                                    <td>SWIFT SOLUTIONS</td>
                                    <td>74, Whispering Palms Shopping Centr Mumbai 27 400101</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>52</td>
                                    <td>THE COLOSSUS CONSULTING</td>
                                    <td>2nd Floor, No.4/9, Circular Road, 4 Chennai 33 600024</td>
                                </tr>
                                <tr>
                                    <td>53</td>
                                    <td>TOTAL REQCOLL</td>
                                    <td>C-22/103/104 Rajeshree shanti nagar Mumbai 27 401107</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>54</td>
                                    <td>TYAGI ASSOCIATES</td>
                                    <td>17/8 Shastri Nagar, Meerut, UP- Meerut 09 250004</td>
                                </tr>
                                <tr>
                                    <td>55</td>
                                    <td>VIJAY RAJ JINDAL & CO</td>
                                    <td>2907 GH G.T.ROAD, Near Zila Parisha Bathinda 03 151001</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Recovery