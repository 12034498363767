import { useEffect } from "react";
import AOS from 'aos';
import CustomerLoginButton from "./CustomerLoginButton";

export default function Home() {

    useEffect(() => {
        AOS.init({
            duration: 1000
        });
    }, []);

    return (

        <>
            <div className='h-20'></div>

            <section className='min-h-screen hero flex items-center'>
                <div className="container px-6 lg:px-0 xl:w-4/5 2xl:container mx-auto">
                    <div className='grid grid-cols-12'>
                        <div className="col-span-12 md:col-span-8 lg:col-span-6 xl:col-span-5 flex flex-col justify-center align-start">
                            <div className='flex flex-col align-start' data-aos={"fade-up"}>
                                <h2 className='heading1 text-start'>Get Out of Debt</h2>
                                <div className='flex justify-start'>
                                    <p className='heading2'>Stress-Free</p>
                                </div>
                                <div className='border-b-4 border-green-500 w-1/5 md:w-52  pt-4'></div>
                                <p className='text-white bannertext mt-10 mr-20  text-start'>
                                    IARC is a debt resolution platform that puts the customer first. Our goal is to put the customer first, and to take the worry and stress out of the debt resolution process
                                </p>
                            </div>
                            <CustomerLoginButton styling={'bg-white text-black mt-8'} />
                        </div>
                    </div>
                    <div className="hidden md:block md:col-span-7 banner">
                    </div>
                </div>
            </section>

            <section className='min-h-screen'>
                <div className='container px-6 lg:px-0 xl:w-4/5 2xl:container mx-auto min-h-screen py-10 lg:py-0'>
                    <div className='grid grid-cols-11 min-h-screen'>
                        <div className='col-span-11 take lg:col-span-5 flex items-end lg:min-h-screen justify-center'>
                            <img
                                src='/assets/shutterstock_1725877684 1.png'
                                className='w-2/4 lg:w-full mx-auto'
                                alt='Resolve your debt'
                            />
                        </div>
                        <div className='hidden lg:block lg:col-span-1'></div>
                        <div className='col-span-11 lg:col-span-5 flex items-center lg:min-h-screen'>
                            <div data-aos={"fade-up"}>
                                <p className='text-black font-bold put text-[40px] text-start'>Customized Debt Relief</p>
                                <p className='text-black font-bold put text-[40px] text-start'>Solutions</p>
                                <div className='border-b-4 border-green-500 w-1/5 md:w-52 pt-4'></div>
                                <div className='flex justify-center'>
                                    <p className='text-black with text-base mt-5 mb-5 md:mt-10 text-start'>
                                    Debt settlement is possible! At IARC, we help you live a debt-free life with our flexible and convenient payment plans customized to your unique needs.
                                    </p>
                                </div>
                                <CustomerLoginButton styling={'bg-black text-white mt-6'} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='newsec min-h-screen w-full flex items-center'>
                <div className='container px-6 lg:px-0 xl:w-4/5 2xl:container mx-auto'>
                    <div className="grid grid-cols-12">
                        <div className="col-span-12 md:col-span-8 lg:col-span-6 xl:col-span-5 flex flex-col justify-center align-start" data-aos={"fade-up"}>
                            <div className='flex flex-col align-start'>
                                <h2 className='heading1 text-start'>Put Yourself Back</h2>
                                <div className='flex justify-start'>
                                    <p className='heading2'>in Control</p>
                                </div>
                                <div className='border-b-4 border-green-500 w-1/5 md:w-52 pt-4'></div>
                                <p className='text-white bannertext mt-10 mr-20 text-start'>
                                    With IARC, the resolution of debt can be simple and straightforward. We provide you with the tools and resources you need to regain control of your finances, repair your credit score, and improve your creditworthiness.
                                </p>
                            </div>
                            <CustomerLoginButton styling={'bg-white text-black mt-8'} />
                        </div>
                    </div>
                </div>
            </section>

            <section className='min-h-screen flex items-center'>
                <div className='container px-6 lg:px-0 xl:w-4/5 2xl:container mx-auto'>
                    <div className='grid grid-cols-11'>
                        <div className='col-span-11 take lg:col-span-4 xl:col-span-5 lg:px-6 2xl:px-0 flex items-center justify-center'>
                            <img src='/assets/home3.png' className='w-3/4 md:w-2/4 lg:w-full 2xl:w-3/4 mx-auto h-auto' alt='Customer-Centered Debt Resolution' />
                        </div>
                        <div className='lg:col-span-1'></div>
                        <div className='col-span-11 lg:col-span-6 xl:col-span-5 flex flex-col items-center' data-aos={"fade-up"}>
                            <div className='mt-20'>
                                <p className='text-black font-bold put text-[40px] text-start'>Revolutionary Digital Platform for Convenient Debt Management</p>
                                <div className='border-b-4 border-green-500 w-1/5 md:w-52 pt-4'></div>
                                <div className='flex justify-center'>
                                    <p className='text-black with text-base mt-4 md:mt-10 text-start mb-10'>Our revolutionary platform provides comprehensive debt management solutions tailored to your unique needs, no matter where you are in the debt resolution process. With our user-friendly interface and easy-to-use tools, managing your debt has never been simpler. Experience the convenience of managing your debt at your fingertips with our innovative digital platform.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}