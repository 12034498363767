import './App.css';
import BODLayout from './bodLayout';


const Bodprav = () => {

    return (
        <BODLayout
            name='Mr. Praveen Kadle'
            about={
                <>
                    Mr. Praveen Kadle is qualified as a Chartered Accountant, Cost & Management Accountant and Company Secretary and a Hons. Graduate in Commerce & Accountancy from Mumbai University.
                    <br /><br />
                    Over the last more than 30 years, Mr. Kalde has held various senior positions in TATA group. 1991-1996 – Tata IBM Ltd – VP Finance (CFO).
                    <br /><br />
                    1996-2001 – Tata Motors Ltd – Senior VP Finance and Corporate Affairs (CFO) 2001-2008 – Tata Motors Ltd – Executive Director & Corporate Affairs (CFO) 2008-2018 – Tata Capital Ltd –Founding Managing Director & CEO.
                    <br /><br />
                    2018 – 2023 – Chairman of Tata Autocomp Systems Ltd.
                    <br /><br />
                    During his stint in Tata Motors, he led the teams that acquired Daewoo Truck Company in South Korea in 2004 and Jaguar Land Rover in 2008. In 2004, as a CFO & Finance Director, he was instrumental in getting Tata Motors listed on New York Stock Exchange and has played a major role in the turnaround and growth of the company.
                    <br /><br />
                    During his ten years in Tata Capital, he had successfully floated 5 private equity funds and set up a very successful Private Equity business of US$ 1 B which has delivered a very attractive IRR in US dollar terms for the international and domestic investors. He is currently a Board Member of Tata International Ltd in the capacity of a Non-executive Director.
                    <br /><br />
                    He has also received a number of awards in recognition of his outstanding contribution to the industry and a few of them are listed below:
                     <ul className='mt-4 list-disc	'>
                        <li> CNBC-TV18, the Country's best performing CFO in the Auto & Auto Ancillaries sector for 2006;</li>
                        <li>  ‘The Best CFO of the year 2005’ in India by Business Today;</li>
                        <li> The ‘CFO of the year 2004’ by IMA (formerly known as Economist Intelligence Unit) and Inducted as Founding member to the CFO Hall of Fame in India.</li>                       
                        <li> Global Indian Business Leader of the year award from Horasis, an international think tank in the year 2015</li>
                        <li> Certificate of Achievement for Outstanding & Exemplary Achievements in Entrepreneurship in the ‘Financial Services Industry’ category by the Asia Pacific Entrepreneurship Awards in 2017</li>
                     </ul>
                </>
            }
            img='/assets/Group 1081105prav.png'
            designation='Independent Director'
        />
    )
}
export default Bodprav