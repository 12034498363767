import {
    GoogleReCaptchaProvider,
    GoogleReCaptcha,
  } from "react-google-recaptcha-v3";
  
const CaptchaGoogleV3 = ({handleVerify})=>{
    return(
        <div className="mb-5 text-left">
                    <GoogleReCaptchaProvider 
                      reCaptchaKey={"6Lfc7TklAAAAAEsYqrljlyQt3K6FOHHnrakyngUW"}
                      >
                      <GoogleReCaptcha onVerify={handleVerify} />
                    </GoogleReCaptchaProvider>
                  </div>
    )
}

export default CaptchaGoogleV3;
