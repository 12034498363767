import BODLayout from './bodLayout';

const Bodhim = () => {
	return (
		<BODLayout
			name='Mr. Naveen Jain'
			about={
				<>
					Mr. Naveen Jain has over 17 years of experience in the field of Equity research
					and Private equity investments. Naveen has been a part of growth-stage
					private investment firm Florintree Advisors since 2017, where he is
					involved in overseeing firm’s investments in private as well as public
					markets. Prior to joining Florintree, Naveen has spent over 10 years
					as a lead research analyst for various institutional equities
					platforms. His last role was at the leading investment bank JM
					Financial, where he led research for Cement and Infrastructure sectors
					for its institutional broking business. Naveen is a Chartered Account
					as well as an MBA in Finance by qualification.
				</>
			}
			img='/assets/Naveen.jpeg'
			designation='Nominee Director of Florintree Evolution Private Limited.'
		/>
	);
};
export default Bodhim;
