import './App.css';


const Agm_7sept_2020 = () => {

    return (
        <>
            <div className='h-20'></div>
            <div className='heading-rounded m-auto text-center text-white font-bold py-4 w-11/12 md:w-3/5'> AGM 7th Sept 2020</div>
            <div className='container px-6 xl:px-0 xl:w-4/5 2xl:container mx-auto py-20'>
                <p className='agm mt-10 flex justify-center'>
                    Notice is hereby given that the 18th Annual General Meeting (AGM) of the members of International Asset Reconstruction Company Private Limited (“IARC”) will be held on Monday, 07th September 2020 at 2:00 PM through Video Conferencing (‘VC’)/Other Audio Visual Means (‘OAVM’) facility to transact the following: -
                </p>
                <p className='agm mt-16 '>ORDINARY BUSINESS</p>
                <ol className='list-decimal mt-6'>
                    <li className='agm_27_2021_li'>
                        To receive, consider and adopt the Audited Financial Statements of the Company for the financial year ended 31st March, 2020, together with the Reports of the Directors’ and Auditors’ Reports thereon.
                        <br></br><br></br>
                    </li>
                    <p className='agm mb-4'>SPECIAL BUSINESS:</p>
                    <li className='agm_27_2021_li'>
                        Regularization of Appointment of Mr. Harjit Singh Bhatia, Independent Director.
                        <br></br><br></br>
                        To consider and if thought fit, pass the following resolution, with or without modification, <strong>Ordinary Resolution:</strong><br></br><br></br>
                        <strong>“RESOLVED THAT</strong>pursuant to the provisions of Section 149, 152 and 160 of the Companies Act, 2013 (“the Act”) and the Rules thereunder (including any statutory modification(s) or re‐enactment there of for the time being in force), and other applicable laws, rules and regulations for the time being in force, if any, Mr. Harjit Singh Bhatia (DIN: 02285424), who was appointed as an Additional Director of the Company by the Board of Directors under Section 161 of the Act and who holds office up to the date of this Annual General Meeting, being eligible, be and is hereby appointed as a the Director of the Company.”
                        <br></br> <br></br>
                        <strong>“RESOLVED FURTHER THAT </strong>the Board of Directors and/or Company Secretary of the Company be and are hereby authorized to do all such acts, deeds, matters and things as may be deemed necessary, proper or desirable for the purpose of giving effect to this Resolution.”
                        <br></br><br></br>
                        In accordance with the provisions of Section 102 of the Companies Act, 2013, the Explanatory Statement, setting out all material facts relating to the Item No. 2 as mentioned in the notice is placed below:
                        <br></br> <br></br>
                        <strong>Item No. 2:</strong>
                        <br></br><br></br>
                        Pursuant to the Articles of Association of the company, and on recommendation of the Nomination & Remuneration Committee, the Board of Directors in their meeting held on 21st May 2019 appointed Mr. Harjit Singh Bhatia (DIN: 02285424) as an Additional Director (Independent) on the Board, subject to the approval from the Reserve Bank of India (RBI). The said approval from RBI was received on 21st November 2019.
                        <br></br><br></br>
                        Mr. Harjit Singh Bhatia holds office up to the date of this Annual General Meeting of the Company, and is eligible for appointment as a Director, pursuant to the provisions of Section 161 of the Companies Act 2013.
                        <br></br><br></br>
                        <string>Brief background of Mr. Harjit Singh Bhatia</string>
                        <br></br><br></br>
                        Mr. Harjit Singh Bhatia is the Executive Chairman & Chief Executive Officer of Asia Growth Capital Advisors (Singapore) Pte Ltd.
                        <br></br><br></br>
                        Mr. Bhatia founded Asia Growth Capital Advisors (AGCA) in August 2010 as a spin-off business from Credit Suisse Bank in Asia. AGCA is a private equity firm, headquartered in Singapore, with offices/affiliates in Mumbai, Hong Kong, Seoul and Shanghai. AGCA is registered with MAS and holds Capital Markets license.
                        <br></br><br></br>
                        Till July 2010, Mr. Bhatia was the Chairman and founding Managing Partner of Credit Suisse Private Equity Asia, a pan Asian fund sponsored by Credit Suisse as the exclusive investment vehicle in the region with international investors. Mr. Bhatia joined Credit Suisse in April 2007 from Ritchie Capital Management (RCM) where he was Chairman and CEO, Asia Pacific and led RCM’s expansion into private equity investing in the Asia Pacific region. From July 2012 to November 2014, Mr. Bhatia also led and managed Asia Opportunities Fund II (originally sponsored by AIG) and related AIA co-investment programs, as Managing Partner & CEO at Pine Bridge Asia Partners.
                        <br></br><br></br>
                        Prior to RCM, Mr. Bhatia spent 11 years at General Electric Company (GE) in senior leadership positions, most recently as President and CEO of GE Corporate Financial Services in Asia Pacific. While at GE, Mr. Bhatia pioneered and founded one of the first dedicated private equity investment programs in Asian private equity (1997-2002) across Asia Pacific with offices in Tokyo, Sydney, Mumbai, New Delhi, Jakarta, Singapore, and Hong Kong with focus on investing in growth sectors.
                        <br></br><br></br>
                        From 2003 to 2005, Mr. Bhatia led GE’s Asia-Pacific business (Global Financial Restructuring Business) focused on providing structured solutions to banks through distressed asset acquisition and broad corporate financial services, managing investment and portfolio management teams across offices in Hong Kong, Tokyo, Taipei, Beijing, Seoul, Bangkok, and Delhi. Prior to working at GE, Mr. Bhatia founded and led Deutsche Bank’s investment banking business in New Delhi (1991-1994), and worked at the State Bank of India (India’s largest commercial bank) for 18 years in various leadership positions in India and abroad (Japan and Italy), including being part of the team which founded India’s largest investment bank SBI Caps.
                        <br></br><br></br>
                        Overall, Mr. Bhatia has over 45 years of broad management experience across corporate lending, private equity, stressed assets investing, corporate finance, infrastructure, and international finance. Mr. Bhatia has sat on the Boards of several portfolio companies in the region during his career. He is well known in the industry and is a frequent participant / speaker at industry dialogues and conferences. He is currently on the Asian Advisory Council of Global Emerging Markets Private Equity Association. Mr Bhatia is also an advisor to CPPIB for their infrastructure investments in India through their investment company he helped set up in Singapore. He also sits on the Board of Trustees of SINDA (Patron Mr Lee H Loong PM of Singapore and Chairman Mr Tharman, former Dy Prime Minister and now Senior Minister in charge of public policy). He also sits on the Board of International Asset Reconstruction Company (majority owned by Blackstone with HDFC Bank and Tata Capital as co-investors) which is focused on investing in stressed assets.
                        <br></br><br></br>
                        Mr. Bhatia holds an MBA from the Faculty of Management, University of Delhi, India and a Master of Commerce from the University of Allahabad, Allahabad, India. He is also a Certified Associate of the Indian Institute of Bankers. Mr. Bhatia obtained a “Green Belt” certification under GE’s Six Sigma Quality Program. He is fluent in English, Hindi, Punjabi, and Urdu.
                        <br></br><br></br>
                        The Board is of the view that Mr. Bhatia’s knowledge and experience will be of immense benefit and value to the Company and, the Board therefore recommends the resolution in relation to appointment of Mr. Harjit Singh Bhatia as Director, for the approval by the shareholders of the Company.
                        <br></br><br></br>
                        Mr. Harjit Singh Bhatia is not disqualified from being appointed as Director in terms of Section 164 of Companies Act, 2013 and has given his consent to act as Director.
                        <br></br><br></br>
                        Except Mr. Harjit Singh Bhatia, none of the Directors and Key Managerial Personnel of the Company and their relatives are concerned or interested, financial or otherwise, in the resolution set out as Item No. 2
                        <br></br><br></br>
                    </li>
                </ol>
                <strong>Notes</strong>
                <ol className='list-decimal mt-2 pl-5 mb-14'>
                    <li className='agm_27_2021_li'>
                        An Explanatory Statement pursuant to Section 102 of the Companies Act, 2013, (‘the Act’) relating to the Special Business to be transacted at the Annual General Meeting (‘AGM’) is annexed hereto. The Board of Directors have considered and decided to include the Item No. 2 listed above as Special Business in the forthcoming AGM, as it being unavoidable in nature.
                    </li>
                    <li className='agm_27_2021_li'>
                        In view of the continuing restrictions on the movement of people at several places in the country, due to outbreak of COVID-19, the Ministry of Corporate Affairs (MCA), vide its General Circular No. 20/2020 dated 5th May, 2020 read with General Circular No. 14/2020 dated 8th April, 2020 and General Circular No. 17/2020 dated 13th April, 2020 has allowed the Companies to conduct the AGM through Video Conferencing (VC) or Other Audio Visual Means (OAVM) during the calendar year 2020. In accordance with, the said circulars of MCA and applicable provisions of the Act, the 18th AGM of the Company shall be conducted through VC / OAVM.
                    </li>
                    <li className='agm_27_2021_li'>
                        As the AGM shall be conducted through VC / OAVM, the facility for appointment of Proxy by the Members is not available for this AGM and hence the Proxy Form and Attendance Slip including Route Map are not annexed to this Notice.
                    </li>
                    <li className='agm_27_2021_li'>
                        Members are requested to note the designated email address <a href="naina.kurane@iarc.co.in">naina.kurane@iarc.co.in</a> for the conveying the vote, where a poll is required during the meeting on any resolution.
                    </li>
                    <li className='agm_27_2021_li'>
                        Institutional / Corporate Members are requested to send a scanned copy (PDF / JPEG format) of the Board Resolution authorising its representatives to attend and vote at the AGM, pursuant to Section 113 of the Act, at <a href="mailto:naina.kurane@iarc.co.in">naina.kurane@iarc.co.in</a>.
                    </li>
                    <li className='agm_27_2021_li'>
                        Members attending the AGM through VC / OAVM shall be counted for the purpose of reckoning the quorum under Section 103 of the Act.
                    </li>
                    <li className='agm_27_2021_li'>
                        The Directors’ Report, Auditors’ Report, Audited Financial Statements as at 31st March 2020 are enclosed.
                    </li>
                    <li className='agm_27_2021_li'>
                        The Company’s Registrar and Share Transfer Agent for its share registry (physical and electronic) are Link Intime India Private Limited having their Registered Office at 247 Park, Tower-C, 101, 1st Floor, L.B.S. Marg, Vikhroli (W), Mumbai-400 083.
                    </li>
                </ol>
            </div>
        </>
    );
}

export default Agm_7sept_2020;