import AOS from 'aos';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchJobs } from './services/service';

const Careers = () => {
    const navigate = useNavigate();
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const getJobs = async () => {
          try {
            const res = await fetchJobs();

            if(res.success) {
                setJobs(res.success.data);
            }
            setLoading(false);
          } catch (error) {
            setError(error.message);
            setLoading(false);
          }
        };
    
        getJobs();
      }, []);

    function CarrerCard({ title, category, overview, description, img, delay, requirements, onClick }) {
        return (
            <div className='pb-5 grid-span-3 md:grid-span-1 h-full flex justify-center cursor-pointer' data-aos='fade-up' data-aos-delay={delay} onClick={onClick}>
                <div className='carrer-box p-8 flex flex-col justify-between h-full w-full'>
                    <div className='flex flex-col items-center'>
                        <img
                            src={img}
                            alt={title}
                            className='mt-4'
                        />
                        <p className='legal my-6 text-center'>{title}</p>
                    </div>
                    <div className='flex justify-center mt-6'>
                        <div className='carrer-button'>
                            View description
                        </div>
                    </div>
                </div>
            </div>
        );
    }


    useEffect(() => {
        AOS.init({
            duration: 1000
        });
    }, []);

    const handleCardClick = (job) => {
        navigate(`/career/${job._id}`, { state: { job } });
      };

    if (loading) {
        return <p>Loading...</p>;
    }

    return (
        <>
            <div className='h-20'></div>
            <section>
                <div className='heading-rounded m-auto text-center text-white font-bold py-4 w-11/12 md:w-3/5'>
                    Careers
                </div>
                <div className='container px-6 xl:px-0 xl:w-4/5 2xl:container mx-auto py-20'>
                    <p className='careers flex justify-center text-center w-11/12 md:w-4/5 mx-auto'>
                        We offer one of the best working environments, flexibility, invest extensively in training and development and have a culture of respect and meritocracy. We would be delighted to hear from you and in case you wish to join us please share your resume at careers@iarc.co.in.
                    </p>
                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 my-10 gap-6'>
                        {jobs.map(job => {
                            return (
                                <CarrerCard
                                    key={job._id}
                                    overview={job.overview}
                                    title={job.title}
                                    description={job.description}
                                    category={job.category}
                                    img='/assets/hiring.svg'
                                    delay='0'
                                    onClick={() => handleCardClick(job)}
                                />
                            )
                        })}
                    </div>
                </div>
            </section>


        </>
    );
}

export default Careers;