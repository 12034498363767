import './App.css';


const Egm_16feb_2021 = () => {

    return (
        <>
            <div className='h-20'></div>
            <div className='heading-rounded m-auto text-center text-white font-bold py-4 w-11/12 md:w-3/5'> EGM 16th Feb 2021</div>
            <div className='container px-6 xl:px-0 xl:w-4/5 2xl:container mx-auto py-20'>
                <div className="fw-col-xs-12 ">
                    <div className="text_block">
                        <p className='text-xl mt-10'>
                            NOTICE is hereby given that an <strong>Extraordinary General Meeting (“EGM”)</strong>  of the Members of International Asset Reconstruction Company Private Limited (“IARC”) will be held on <strong>Tuesday, 16th February 2021 at 1:00 P.M.</strong> through Video Conferencing (‘VC’)/Other Audio Visual Means (‘OAVM’) facility to transact the following:
                        </p>

                        <p className='agm mt-10'>
                            SPECIAL BUSINESS
                        </p>

                        <p className='agm mt-10'>
                            <strong>TO APPROVE APPOINTMENT OF STATUTORY AND TAX AUDITORS OF THE COMPANY.</strong>
                        </p>

                        <p className='agm_27_2021_li mt-5'>
                            To consider and if thought fit, pass the following resolution, with or without modification, as an <strong>Ordinary Resolution</strong>:
                        </p>

                        <p className='agm_27_2021_li'>
                            “<strong>RESOLVED THAT</strong> subject to provisions of Section 139(8), Section 140(2) of the Companies Act, 2013 and pursuant to Companies (Audit & Auditors) Rules, 2014 and other applicable provisions, if any and in accordance with Article 2.1 & 11.9 of the Articles of Association of the Company, approval of members be and are hereby accorded for appointment of M/s Deloitte Haskins & Sells LLP, Chartered Accountants, (Firm Registration No. with ICAI – 117366W/W100018) as Statutory Auditors of the Company to hold office till the conclusion of ensuing Annual General Meeting in place of M/s. Price Waterhouse Chartered Accountant LLP, who have expressed their unwillingness to continue for the rest of the tenure and placed their resignation to the Board.
                        </p>

                        <p className='agm_27_2021_li'>
                            <strong>RESOLVED FURTHER THAT</strong> Board of Directors of the Company be and are hereby authorized to fix the remuneration including out of pocket expenses of the Statutory Auditors of the Company."
                        </p>
                        <p className='agm_27_2021_li'>
                            Appointment of M/s Deloitte Haskins & Sells LLP, Chartered Accountants, (Firm Registration No. with ICAI –117366W/W100018) as Statutory And Tax Auditors of the Company under casual vacancy.
                        </p>
                        <p className='agm_27_2021_li'>
                            M/s. Price Waterhouse Chartered Accountant LLP, (Firm Registration No. with ICAI -012754N/N5000016) have resigned vide letter dated 20th October 2020 from the position of Statutory Auditors of the Company on account of differing expectations in respect of fees for Audit Engagement,
                            resulting into casual vacancy in the office of the statutory Auditors of the Company.
                        </p>
                        <p className='agm_27_2021_li'>
                            As envisaged by the section 139(8) of the Companies Act, 2013 (“the Act”) casual vacancy caused by the resignation of auditors shall be filled by the Board within 30 days of such vacancy and the same shall be approved by the Company in the general meeting within 3(three) months of the Board recommendation. Therefore, approval of members is sought to appoint M/s Deloitte Haskins & Sells LLP, Chartered Accountants, (Firm Registration No. with ICAI – 117366W/W100018), as Statutory Auditors of the Company to fill the casual vacancy caused by the resignation of M/s. Price Waterhouse Chartered Accountant LLP, till the conclusion of the ensuing Annual General Meeting of the Company.
                        </p>
                        <p className='agm_27_2021_li'>
                            M/s Deloitte Haskins & Sells LLP, Chartered Accountants, (Firm Registration No. with ICAI – 117366W/W100018), have conveyed their consent and eligibility to be appointed as the Statutory Auditors of the Company along with a confirmation that, their appointment, if made by the members, would be within the limits prescribed under Companies Act, 2013.
                        </p>
                        <p className='agm_27_2021_li'>
                            None of the Directors and Key Managerial Personnel of the Company and their relatives are concerned or interested, financial or otherwise, in the resolution.
                        </p>
                        <p className='agm_27_2021_li'>
                            Your Directors recommend the passing of the aforesaid resolution as an ordinary resolution.
                        </p>
                        <ol className='list-decimal mt-2 pl-5 mb-14'>
                            <li className='agm_27_2021_li'>
                                An Explanatory Statement pursuant to Section 102 of the Companies Act, 2013, (‘the Act’) relating to the Special Business to be transacted at the Extraordinary General Meeting (‘EGM’) is annexed hereto. The Board of Directors have considered and decided to include the Item Nos. 1 given above as Special Business in the EGM, as they are unavoidable in nature.
                            </li>

                            <li className='agm_27_2021_li'>
                                The Ministry of Corporate Affairs (MCA), in continuation to this Ministry's General Circulars No. 14/2020 dated 8th April 2020, No.17/2020 dated 13th April 2020, No.22/2020 dated 15th June 2020 and No.33/2020 dated 28th September 2020 and recent circular dated 31st December 2021, after due examination, decided to allow companies to conduct their EGMs through VC or OAVM or transact items through postal ballot in accordance with the framework provided in the aforesaid Circulars upto 30th June, 2021. In accordance with, the said circulars of MCA and applicable provisions of the Act, the EGM of the Company shall be conducted through VC / OAVM.
                            </li>

                            <li className='agm_27_2021_li'>
                                As the EGM shall be conducted through VC / OAVM, the facility for appointment of Proxy by the Members is not available for this EGM and hence the Proxy Form and Attendance Slip including Route Map are not annexed to this Notice.
                            </li>

                            <li className='agm_27_2021_li'>
                                Members are requested to note the designated email address iarc@iarc.co.in for the conveying the vote, where a poll is required during the meeting on any resolution.
                            </li>

                            <li className='agm_27_2021_li'>
                                Institutional / Corporate Members are requested to send a scanned copy (PDF / JPEG format) of the Board Resolution authorising its representatives to attend and vote at the EGM, pursuant to Section 113 of the Act, at <a href="mailto:iarc@iarc.co.in">iarc@iarc.co.in</a>.
                            </li>

                            <li className='agm_27_2021_li'>
                                Members attending the EGM through VC / OAVM shall be counted for the purpose of reckoning the quorum under Section 103 of the Act.
                            </li>

                            <li className='agm_27_2021_li'>
                                The consent letter and eligibility certificate from M/s Deloitte Haskins & Sells LLP, Chartered Accountants as required under Section 139(1) of the Act with respect to Item No. 1 is enclosed.
                            </li>
                            <li className='agm_27_2021_li'>
                                The Company’s Registrar and Share Transfer Agent for its share registry (physical and electronic) are Link Intime India Private Limited having their Registered Office at 247 Park, Tower-C, 101, 1st Floor, L.B.S. Marg, Vikhroli (W), Mumbai-400 083.
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Egm_16feb_2021;