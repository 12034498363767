import axios from 'axios';

const callAPI = (route, endPoint, body = {}, token = "", externalUrl = false, recaptcha="") => {
  let url = externalUrl ? endPoint : endPoint ? `${process.env.REACT_APP_API_BASE_URL}${endPoint}`:`${process.env.REACT_APP_API_BASE_URL}${route.endPoint}`;
  
  const headerToken = '';
  const userdata = headerToken && JSON.parse(headerToken);
  const headers = route.isAuthRequired ? { Authorization: `Bearer ${token || userdata.jwt}`, Recaptcha: `Recaptcha ${recaptcha}`} : {Recaptcha: `Recaptcha ${recaptcha}`};

  const responseType = route.responseType;
  return new Promise(async (resolve) => {
    let axiosRequest = null;
    let options = {
      headers, responseType,timeout: 3000*60
    };
    if (route.verb === 'get') {
      axiosRequest = axios[route.verb](url, options);
    }
    else if(route.verb === 'delete') {
      options.data = body;
      axiosRequest = axios[route.verb](url, options);
    }
    else {
      axiosRequest = axios[route.verb](url, body, options);
    }
    await axiosRequest
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        if(err.response.data && err.response.data.status === 401){
          if(process.env.REACT_APP_BASE_NAME === "/"){
            window.location = "/login";
          }
          else{
            window.location = process.env.REACT_APP_BASE_NAME+"/login";

          }
        } else if(err.request) {
          console.log(err.request);
        } else {
          console.log(err.message);
        }
        resolve(err.response);
      })
  })

}

export { callAPI }
