import { useEffect } from "react";
import AOS from "aos";

const Whatwedo = () => {

    useEffect(() => {
        AOS.init({
            duration: 1000
        });
    }, []);

    return (
        <>

            <section className='min-h-screen bannerwhat wwd-1 flex flex-col'>
                <div className='h-20'></div>
                <div className='heading-rounded mx-auto text-center text-white font-bold py-4 w-11/12 md:w-3/5'>
                    Retail Non-Performing Loans
                </div>
                <div className='grow flex items-center py-6'>
                    <div className='container px-6 lg:px-0 xl:w-4/5 2xl:container mx-auto' data-aos={"fade-up"}>
                        <h2 className='text-white text-center font-bold text-4xl mb-20'>
                            Customer centricity at the core
                        </h2>
                        <p className='text-white text-center bannerwhattext'>
                            Though our processes are designed to ensure timely and convenient loan resolution, sometimes defaults occur due to circumstances beyond customers’ control. This is where our customer-centric approach and emphasis on developing solutions to assist them in repaying lenders at their convenience come into play!<br /><br />
                            We make every effort to provide our customers with solutions that meet their financial needs and circumstances. Though our primary mode of communication with our customers is online, they can also interact with our resolution associates via phone, in-person discussions with field executives, or online chat in a language of their choice. They can view their account history, repayment options, and resolution plans. Above all, we strive to give our customers the best service at par with any world-class institution when it comes to managing their loans.
                        </p>
                    </div>
                </div>
            </section>

            <section className='py-10 flex items-center'>
                <div className='container px-6 lg:px-0 xl:w-4/5 2xl:container mx-auto'>
                    <div className='grid grid-cols-11'>
                        <div className='col-span-11 take lg:col-span-4 xl:col-span-5 lg:px-6 2xl:px-0 flex items-center justify-center pt-10 lg:pt-0'>
                            <img
                                src='/assets/what-we-do-img2.png'
                                className='w-2/4 lg:w-full 2xl:w-3/4 mx-auto h-auto'
                            />
                        </div>
                        <div className='lg:col-span-1'></div>
                        <div className='col-span-11 lg:col-span-6 xl:col-span-5 flex flex-col items-center'>
                            <div className='mt-20' data-aos={"fade-up"}>
                                <p className='text-black font-bold put text-[40px] text-start'>Operating with transparency</p>
                                <p className='text-black font-bold put text-[40px] text-start'>and responsibility</p>
                                <div className='border-b-4 border-green-500 w-1/5 md:w-52 mt-10'></div>
                                <div className='flex justify-center'>
                                    <p className='text-black with text-base mt-4 md:mt-10 text-start mb-10'>
                                        IARC's data-driven approach offers a holistic approach to managing and resolving NPLs. We operate responsibly and in compliance with our values, offering sustainable recovery plans to individuals and businesses in the retail space.<br /><br />
                                        As one of the country's leading and most reliable ARCs, we are paving the way to managing NPLs through transparency, openness, and fairness. At IARC, we place a high value on ethical recovery, offering debtors the choice of managing repayment plans.<br /><br />
                                        We also aim to facilitate the financial re-inclusion of individuals, with particular attention to the vulnerable categories. We aim to operate with maximum integrity and positively impact the country's economy by returning productive assets to the mainstream financial system.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='min-h-screen py-10 flex items-center ensure'>
                <div className='container px-6 lg:px-0 xl:w-4/5 2xl:container mx-auto'>
                    <div className='grid grid-cols-11'>
                        <div className='col-span-11 md:col-span-5'>
                            <p className='font-bold ensuring text-white pt-10 md:pt-0'> Ensuring the integrity</p>
                            <p className='font-bold ensuring mb-10 text-white'>of our agents</p>
                            <div className='border-b-4 border-green-500 w-1/5 md:w-52 mt-10 mb-20'></div>
                            <img
                                src='/assets/what-we-do-img4.png'
                                alt='Integrity'
                                className="hidden md:inline-block"
                            />
                        </div>
                        <div className='hidden md:block md:col-span-1'></div>
                        <div className='col-span-11 md:col-span-5'>
                            <img src='/assets/what-we-do-img3.png' className='w-full h-auto' alt="image" />
                            <p className='mt-20 mb-10 text-white' data-aos={"fade-up"}>
                                The resolution process need not be unpleasant or stressful for our customers. Therefore, our conduct – internally and externally through our agent network – complies with high standards of ethical correctness.
                                <br></br><br></br>
                                We verify the approach by regularly contacting our customers – those who have successfully closed their debt exposure with our flexible repayment plans, as well as those with whom an agreement could not be reached, to verify the correctness and integrity of our network agents.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='bg-secondary py-10 flex items-center'>
                <div className='container px-6 lg:px-0 xl:w-4/5 2xl:container mx-auto'>
                    <div className='grid grid-cols-11'>
                        <div className='col-span-11 lg:col-span-6 xl:col-span-5 flex flex-col items-center'>
                            <div className='mt-20' data-aos={"fade-up"}>
                                <p className='text-black font-bold put text-[40px] text-start'>A reliable partner to financial institutions</p>
                                <div className='border-b-4 border-green-500 w-1/5 md:w-52 mt-10'></div>
                                <div className='flex justify-center'>
                                    <p className='text-black with text-base mt-4 md:mt-10 text-start mb-10'>
                                        IARC is one of the first ARCs to set up an end-to-end resolution management system that allows us to give our institutional partners the best service and value. Core Banking Solution, Collection Management System, Legal and Litigation System and a Digital Front-end form the backbone of this system. We can seamlessly transition large loan portfolios to our systems, freeing up valuable resources at your end to focus on your core business – underwriting credit.<br></br><br></br>

                                        In addition, our proprietary algorithms, deep analytics capabilities and automated workflows allow us to manage large portfolios efficiently. Our war room setup can communicate and coordinate with dozens of agencies and stakeholders in real-time to facilitate resolution quickly.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='lg:col-span-1'></div>
                        <div className='col-span-11 take lg:col-span-4 xl:col-span-5 lg:px-6 2xl:px-0 flex items-center justify-center'>
                            <img
                                src='/assets/what-we-do-img5.png'
                                className='w-2/4 lg:w-full 2xl:w-3/4 mx-auto h-auto'
                            />
                        </div>
                    </div>
                </div>
            </section>

            <section className='min-h-screen bannerwhat wwd-2 flex flex-col'>
                <div className='heading-rounded mx-auto text-center text-white font-bold py-4 w-11/12 md:w-3/5'>
                    Wholesale Non-performing Loans
                </div>
                <div className='grow flex items-center'>
                    <div className='container px-6 lg:px-0 xl:w-4/5 2xl:container mx-auto'>
                        <div className='grid grid-cols-3 mt-20 pb-10 gap-y-6 gap-x-14'>
                            <div className='col-span-3 md:col-span-1' data-aos={"fade-up"}>
                                <img src='/assets/wnpl1.png' alt='image' />
                                <h2 className='font-bold whole text-white mt-5 mb-5'>
                                    Specialized<br /> Teams
                                </h2>
                                <p className='wholesec text-white'>
                                    Wholesale NPL resolution requires a specialized skillset to help businesses reach a quick and productive outcome. With decades of experience in the field, our teams can structure solutions that work for our customers in various situations.
                                </p>
                            </div>
                            <div className='col-span-3 md:col-span-1' data-aos={"fade-up"} data-aos-delay="300">
                                <img src='/assets/wnpl2.png' alt='image' />
                                <h2 className='font-bold whole text-white mt-5 mb-5'>
                                    Flexible<br /> Approach
                                </h2>
                                <p className='wholesec text-white'>
                                    IARC's range of solutions is not restricted to restructuring and one-time settlements (OTS). We also provide rescue financing, take-out financing, debt consolidation, and a range of other solutions to support the revival of businesses undergoing temporary distress
                                </p>
                            </div>
                            <div className='col-span-3 md:col-span-1' data-aos={"fade-up"} data-aos-delay="600">
                                <img src='/assets/image 12.png' alt='image' />
                                <h2 className='font-bold whole text-white mt-5 mb-5'>
                                    Fairness to all stakeholders
                                </h2>
                                <p className='wholesec text-white'>
                                    We recognize that multiple stakeholders, including owners, lenders, vendors, employees and customers, are involved in a business undergoing financial distress. We aim to craft solutions that are fair to all stakeholders and within the bounds of the relevant regulations.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='flex flex-col'>
                <div className='heading-rounded mx-auto text-center text-white font-bold py-4 w-11/12 md:w-3/5'>
                    Asset management for distressed debt
                </div>

                <div className='py-10 flex items-center'>
                    <div className='container px-6 lg:px-0 xl:w-4/5 2xl:container mx-auto'>
                        <div className='grid grid-cols-11'>
                            <div className='col-span-11 take lg:col-span-4 xl:col-span-5 lg:px-6 2xl:px-0 flex items-center justify-center'>
                                <img
                                    src='/assets/what-we-do-img6.png'
                                    className='w-2/4 lg:w-full 2xl:w-3/4 mx-auto h-auto'
                                />
                            </div>
                            <div className='lg:col-span-1'></div>
                            <div className='col-span-11 lg:col-span-6 xl:col-span-5 flex flex-col items-center'>
                                <div className='mt-20' data-aos={"fade-up"}>
                                    <p className='text-black font-bold put text-[40px] text-start'>Focused on your goals</p>
                                    <div className='border-b-4 border-green-500 w-1/5 md:w-52 mt-10'></div>
                                    <div className='flex justify-center'>
                                        <p className='text-black with text-base mt-4 md:mt-10 text-start mb-10'>
                                            At IARC, our multi-disciplinary team of professionals works with stakeholders across the spectrum. Our data-driven approach and ability to deploy a range of tools allow us to pursue resolution strategies that safeguard the capital deployed and generate adequate returns.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <section className='bg-secondary py-10 flex items-center'>

                <div className='container px-6 lg:px-0 xl:w-4/5 2xl:container mx-auto'>

                    <div className='grid grid-cols-11'>
                        <div className='col-span-11 lg:col-span-6 xl:col-span-5 flex flex-col items-center'>
                            <div className='mt-20' data-aos={"fade-up"}>
                                <p className='text-black font-bold put text-[40px] text-start'>Delivering consistent,</p>
                                <p className='text-black font-bold put text-[40px] text-start'>risk-adjusted returns</p>
                                <div className='border-b-4 border-green-500 w-1/5 md:w-52 mt-10'></div>
                                <div className='flex justify-center'>
                                    <p className='text-black with text-base mt-4 md:mt-10 text-start mb-10'>
                                        IARC has one of the country's largest alternative investment management platforms, and we are currently managing an AUM of over US$150 million. Our Asset Management business focuses on delivering sustainable and long-term risk-adjusted returns with the underlying principle of capital growth and preservation across product offerings.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='lg:col-span-1'></div>
                        <div className='col-span-11 take lg:col-span-4 xl:col-span-5 lg:px-6 2xl:px-0 flex items-center justify-center'>
                            <img
                                src='/assets/Group 10819.png'
                                className='w-2/4 lg:w-full 2xl:w-3/4 mx-auto h-auto'
                            />
                        </div>
                    </div>

                </div>

            </section>

        </>
    );
}

export default Whatwedo;