import './App.css';


const NRPolicy = () => {

    return (
        <>
            <div className='h-20'></div>
            <div className='heading-rounded m-auto text-center text-white font-bold py-4 w-11/12 md:w-3/5'>IARC NOMINATION & REMUNERATION (“NR”) POLICY</div>
            <section>
                <div className='container px-6 xl:px-0 xl:w-4/5 2xl:container mx-auto py-20'>
                    <p className='name mb-10'>PREAMBLE</p>
                    <p className='pointss mb-5'>
                        International Asset Reconstruction Company Private Limited (“IARC” or “Company”) is an Asset Reconstruction Company (“ARC”) and is registered as such with the Reserve Bank of India (“RBI”).
                    </p>
                    <p className='pointss mb-5'>
                        Pursuant to RBI Notification DOR.SIG.FIN.REC 1/26.03.001/2022-23 of October 14, 2022 (as updated from time to time) (<strong>the “RBI ARC Master Circular 2022”</strong>) ARCs are required to constitute a Nomination and Remuneration Committee of the Board, which shall have the powers, functions and duties as laid down in Section 178 of the Companies Act, 2013 (“Act”).
                    </p>
                    <p className='pointss mb-5'>
                        This policy is in line with Section 178 of the Act and the Rules framed there under. NRC shall formulate the criteria for determining qualifications, positive attributes and independence of a Director and recommend to the Board, a policy relating to the Remuneration for the Directors, Key Managerial Personnel, and other employees.
                    </p>
                    <p className='name mb-5'>OBJECTIVE</p>
                    <p className='pointss mb-5'>
                        The objective of this Policy is to provide a framework for the appointment, removal, and Remuneration of the Independent Directors, non-Executive Directors, Managing Director/Whole-Time Director/Executive Directors, KMPs, and persons who may be appointed in senior management positions of the Company, to recommend the Remuneration to be paid to them and to evaluate their performance.
                    </p>
                    <p className='name mb-5'>APPOINTMENT CRITERIA AND QUALIFICATIONS</p>
                    <p className='name mb-5'>General Criteria</p>
                    <ul style={{ listStyleType: "lower-alpha" }} className="ml-5">
                        <li className='mb-5'>
                            The NRC shall be responsible for evaluating a person for appointment / re-appointment as Director, NRC shall consider and evaluate number of factors including but not limited to background, knowledge, skills, abilities (ability to exercise sound judgement) professional and personal accomplishment, age, experience of industry and such other factors that NRC might consider relevant and applicable from time to time towards achieving a diverse Board.
                        </li>
                        <li className='mb-5'>
                            The proposed candidate shall possess appropriate expertise, experience, and knowledge in one or more fields of technology, finance, law, management, administration, corporate governance, or such other areas related to the Company's business as determined by the NRC.
                        </li>
                        <li className='mb-5'>
                            During the term of the office, every director shall:
                            <ul className='ml-5 mt-5' style={{ listStyleType: "lower-roman" }}>
                                <li className='mb-5'>
                                    uphold ethical standards of integrity and probity;
                                </li>
                                <li className='mb-5'>
                                    act objectively and constructively;
                                </li>
                                <li className='mb-5'>
                                    exercise responsibilities in a bona-fide manner in the interest of the Company;
                                </li>
                                <li className='mb-5'>
                                    shall be free from any disqualifications as stipulated under the Act.
                                </li>
                            </ul>
                        </li>
                        <li className='mb-5'>
                            The Company shall not appoint or continue the employment of any person as Managing Director/CEO and Whole-Time director who has attained the age of seventy years.
                        </li>
                        <li className='mb-5'>
                            NRC shall make recommendations to the Board concerning any matters relating to the continuation in office of any Director at any time including the suspension or termination of service of an Executive Director as an employee of the Company subject to the provision of the law and their service contract.
                        </li>
                        <li className='mb-5'>
                            NRC shall recommend any necessary changes to the Board.
                        </li>
                    </ul>
                    <p className='name mb-5'>Appointment of Independent Directors</p>
                    <p className='pointss mb-5'>
                        The appointment of Independent Director is as per the RBI directions and in compliance with RBI ARC Master Circular 2022 and other circular issued from time to time.
                    </p>
                    <p className='pointss mb-5'>
                        While evaluating a person for appointment / re-appointment as an Independent Director, the NRC shall ensure that the proposed candidate satisfies the following additional criteria:
                    </p>
                    <ul className='list-disc ml-5'>
                        <li className='mb-5'>
                            meets the baseline definition and criteria of “independence” as set out in section 149 of the Act and other applicable laws;
                        </li>
                        <li className='mb-5'>
                            should not hold any Board / employment position with a competitor in the geographies where the Company is operating;
                        </li>
                    </ul>
                    <p className='pointss mb-5'>
                        Independence of Directors is decided on the basis of criteria provided under the relevant provisions of the Act, and any modification/amendments done from time to time. A declaration of independence is taken from the Independent Directors at the first meeting of the Board in which he participates as a director and thereafter at the first meeting of the Board in every financial year or whenever there is any change in the circumstances which may affect his status as an Independent Director. An Independent Director shall be under the obligation to inform the Board of Directors of any change in circumstances which may affect his/her independence.
                    </p>
                    <p className='pointss mb-5'>
                        The re-appointment / extension of term of the Director shall be based on his/her performance evaluation report, and in accordance with Act.
                    </p>
                    <p className='name mb-5'>Appointment of Managing Director/Whole-time Director</p>
                    <p className='pointss mb-5'>
                        The Board based on the recommendation of the NRC, shall approve the appointment for the position of Chairman, Managing Director/Whole-time Director.
                    </p>
                    <p className='pointss mb-5'>
                        The terms and conditions of the appointment shall be in accordance with the provisions of Act, RBI guidelines and their service contract.
                    </p>
                    <p className='name mb-5'>In accordance with Section 178(4) of the Act, this Nomination & Remuneration Policy is being formulated to ensure the following:</p>
                    <ul style={{ listStyleType: "lower-alpha" }} className="ml-5">
                        <li className="mb-5">
                            the level and composition of remuneration is reasonable and sufficient to attract, retain and motivate Directors of the quality required to run the company successfully;
                        </li>
                        <li className="mb-5">
                            relationship of remuneration to performance is clear and meets appropriate performance benchmarks; and
                        </li>
                        <li className="mb-5">
                            remuneration to Directors, key managerial personnel and senior management involves a balance between fixed and incentive pay reflecting short and long-term performance objectives appropriate to the working of the company and its goals:
                        </li>
                    </ul>
                    <p className='pointss mb-5'>
                        This “Nomination & Remuneration Policy” shall apply to the Company's Board of Directors, KMPs and Senior Management. It shall be effective immediately upon approval by the Board, and shall supersede and replace the existing policy, if any, relating to the appointment of Directors, KMPs and Senior Management. This policy shall remain in effect until superseded or amended, or rendered inapplicable by amendments to Act, rules, and regulations applicable to the relevant matters or to an ARC.
                    </p>
                    <p className='pointss mb-5'>
                        Notwithstanding anything contained herein, in case of a conflict between the provisions of this policy and extant laws, rules and regulations, the extant laws, rules, and regulations (including the relevant RBI circulars/guidelines/notifications) shall prevail.
                    </p>
                    <p className='name mb-5'>
                        DEFINITIONS
                    </p>
                    <ul className='list-decimal ml-5'>
                        <li className="mb-5">
                            <strong>Act</strong> means the Companies Act, 2013 and Rules framed thereunder, as amended from time to time.
                        </li>
                        <li className="mb-5">
                            <strong>Board</strong> means Board of Directors of IARC.
                        </li>
                        <li className="mb-5">
                            <strong>Managing Director</strong> means a director who, by virtue of the articles of a company or an agreement with the company or a resolution passed in its general meeting, or by its board of directors, is entrusted with substantial powers of management of the affairs of the company and includes a director occupying the position of managing director, by whatever name called.
                        </li>
                        <li className="mb-5">
                            <strong>Independent Director</strong> means an independent director referred to in subsection (6) of Section 149 of the Act.
                        </li>
                        <li className="mb-5">
                            <strong>Whole-Time Director</strong> includes a Director in the whole-time employment of the Company;
                        </li>
                        <li className="mb-5">
                            <strong>Senior Management</strong> means personnel of the company who are members of its core management team excluding Board of Directors comprising all members of management one level below the executive directors.
                        </li>
                        <li className="mb-5">
                            <strong>Key Managerial Personnel (“KMP”)</strong> as per section 2 (51) of the Act, in relation to a company, means –
                            <ul style={{ listStyleType: "lower-roman" }} className="ml-5">
                                <li>
                                    the Chief Executive Officer or the managing director or the manager;
                                </li>
                                <li>
                                    the company secretary;
                                </li>
                                <li>
                                    the whole-time director;
                                </li>
                                <li>
                                    the Chief Financial Officer;
                                </li>
                                <li>
                                    such other officer, not more than one level below the Director who is in whole-time employment, designated as key managerial personnel by the Board; and
                                </li>
                                <li>
                                    such other officer as may be prescribed.”
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <p className='pointss mb-5'>As per IARC ‘s Article of Association, all officials having designation of President in the Company are KMP.</p>
                    <li className='mb-5'>
                        <strong>Performance Evaluation</strong> shall mean the effective evaluation of performance of Board, the committees thereof, and individual Directors (whether Executive or non-Executive).
                    </li>
                    <li className='mb-5'>
                        <strong>Remuneration</strong> means any money, or its equivalent given or passed to any person for services rendered by him and includes perquisites as defined under the Income-tax Act, 1961 (43 of 1961).
                    </li>
                    <li className='mb-5'>
                        <strong>Nomination & Remuneration Committee (“NRC”)</strong> means the committee of the Board constituted as per Section 178 of the Act.
                    </li>
                    <p className='name mb-5'>
                        GOVERNANCE FRAMEWORK
                    </p>
                    <p className='pointss mb-5'>IARC’s Board shall reconstitute the NRC in time and in line with the RBI directives.</p>
                    <p className='name mb-5'>CURRENT COMPOSITION OF NRC</p>
                    <ul style={{ listStyleType: "lower-alpha" }} className="ml-5">
                        <li className='mb-5'>
                            1 (one) Blackstone India ARC SG FDI Holding (NQ) Co. Pte Limited Nominee Director (if the Investor has the right to nominate an Investor Director on the Board);
                        </li>
                        <li className='mb-5'>
                            1 (one) HDFC Bank Nominee Director (if the Investor has the right to nominate an Investor Director on the Board);
                        </li>
                        <li className='mb-5'>
                            1 (one) Tata Capital Financial Services Limited (if the Investor has the right to nominate an Investor Director on the Board);
                        </li>
                        <li className='mb-5'>
                            Chairman of the Board (Independent Director); and
                        </li>
                        <li className='mb-5'>
                            1 (one) Independent Director.
                        </li>
                    </ul>
                    <p className='name mb-5'>QUORUM OF NRC</p>
                    <p className='pointss mb-5'>At least 3 (three) Directors. Requires mandatory presence of:</p>
                    <ul style={{ listStyleType: "lower-alpha" }} className="ml-5">
                        <li className='mb-5'>
                            1 (one) Blackstone India ARC SG FDI Holding (NQ) Co. Pte Limited Nominee Director <strong>AND</strong>
                        </li>
                        <li className='mb-5'>
                            any 1 (one) of - either HDFC Bank Nominee Director or Tata Capital Financial Services Limited Nominee Director <strong>AND</strong>
                        </li>
                        <li className='mb-5'>
                            1 (one) Tata Capital Financial Services Limited (if the Investor has the right to nominate an Investor Director on the Board);
                        </li>
                        <li className='mb-5'>
                            1 (one) Chairman of the Board .
                        </li>
                    </ul>
                    <p className='name mb-5'>FREQUENCY OF MEETINGS </p>
                    <p className='pointss mb-5'>At least 1(one) meetingper year.</p>
                    <p className='name mb-5'>FUNCTIONS OF NRC</p>
                    <p className='pointss mb-5'>The functions of the NRC shall be:</p>
                    <ul style={{ listStyleType: "lower-roman" }} className="ml-5">
                        <li className='mb-5'>
                            to formulate the criteria for determining qualifications, positive attributes and independence of a Director and recommend to the Board, a policy relating to the Remuneration for the Directors, KMPs, Senior Management and other employees;
                        </li>
                        <li className='mb-5'>
                            to ensure that the level and composition of Remuneration is reasonable and sufficient to attract, retain and motivate Directors of the quality required to run the Company successfully;
                        </li>
                        <li className='mb-5'>
                            to ensure that the relationship of Remuneration to performance is clear and meets appropriate performance benchmarks;
                        </li>
                        <li className='mb-5'>
                            to ensure that the Remuneration to Directors, KMPs and Senior Management involves a balance between fixed and incentive pay reflecting short and long-term performance objectives appropriate to the working of the Company and its goals;
                        </li>
                        <li className='mb-5'>
                            to specify the manner for effective evaluation of performance of Board, its committees, and individual Directors to be carried out either by the Board or by the NRC or by an independent external agency and review its implementation and compliance;
                        </li>
                        <li className='mb-5'>
                            to identify persons who are qualified to become Directors and who may be appointed in Senior Management in accordance with the criteria laid down, recommend their appointment and removal to the Board;
                        </li>
                        <li className='mb-5'>
                            to ensure 'fit and proper' status of proposed/ existing Directors and sponsors;
                        </li>
                        <li className='mb-5'>
                            to identify and recommend the appointment of Independent Directors to the Board;
                        </li>
                        <li className='mb-5'>
                            succession planning and key functions related to Human Resource;
                        </li>
                        <li className='mb-5'>
                            to approve overall salary scales for all employees;
                        </li>
                        <li className='mb-5'>
                            to approve annual performance bonus to employees and grant of annual increment to employees and;
                        </li>
                        <li className='mb-5'>
                            to approve performance bonus and increments to MD & CEO;
                        </li>
                        <li className='mb-5'>
                            to approve the HR policies;
                        </li>
                        <li className='mb-5'>
                            to approve fees to Directors.
                        </li>
                    </ul>
                    <p className='name mb-5'>PERFORMANCE EVALUATION PROCESS</p>
                    <p className='pointss mb-5'>The process for Performance Evaluation of Board is designed to:</p>
                    <ul className='list-disc ml-5'>
                        <li className='mb-5'>
                            annually assess the performance of individual Director in discharging his/her responsibilities; and
                        </li>
                        <li className='mb-5'>
                            evaluate the Director’s confidence in the integrity of the Company, the quality of the discussions at Board meetings, the credibility of the reports and information they receive, the level of interpersonal cohesion between Board members and the degree of Board knowledge.
                        </li>
                    </ul>
                    <p className='pointss mb-5'>
                        The Performance Evaluation shall be carried out annually against the parameters indicated in the respective questionnaires are annexed as Annexure I to Annexure IV. The process for Performance Evaluation may involve the following steps:-
                    </p>
                    <ul className='list-disc ml-5'>
                        <li className='mb-5'>
                            Identification of areas for performance evaluation;
                        </li>
                        <li className='mb-5'>
                            Formulating a questionnaire on the areas for performance evaluation;
                        </li>
                        <li className='mb-5'>
                            Obtaining responses of individual Directors to the questionnaire on a rating scale;
                        </li>
                        <li className='mb-5'>
                            Analysing the responses to the questionnaire; and
                        </li>
                        <li className='mb-5'>
                            Reporting the findings to the Board.

                        </li>
                    </ul>
                    <p className='pointss mb-5'>The Performance Evaluation of Independent Directors shall be done by the entire Board, excluding the Director being evaluated. On the basis of the report of Performance Evaluation, it shall be determined whether to extend or continue the term of appointment of the Independent Director.</p>
                    <p className='name mb-5'>DISCLOSURE</p>
                    <p className='pointss mb-5'>
                        Nomination & Remuneration Policy shall be placed on the website of the Company and the salient features of the said policy and changes therein, if any, shall be disclosed in the Board's report.
                        The terms and conditions of appointment of Independent Directors shall also be posted on the Company’s website.
                    </p>
                    <p className='name mb-5'>REVIEW</p>
                    <p className='pointss mb-5'>The Nomination & Remuneration Policy shall be placed for review by the Board every two years, or as needed.  </p>
                </div>
            </section>
        </>
    )
}
export default NRPolicy