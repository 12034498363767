import AOS from 'aos';
import { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom'
import { Modal } from './components/modal';

function PolicyBox({
    img, heading, link, delay
}) {
    return (
        <Link to={link} className='flex items-center justify-center' data-aos='fade-up' data-aos-delay={delay}>
            <div className='policy-box'>
                <img src={img} alt={heading} />
                <p className='policies mt-2'>{heading}</p>
                <button className="bg-transparent mt-6 butto hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border hover:border-transparent rounded">
                    View details
                </button>
            </div>
        </Link>
    );
}

const Investor = () => {

    const [modalVisible, setModalVisle] = useState(false);
    const location = useLocation();
    const policyRef = useRef();

    useEffect(() => {
        AOS.init({
            duration: 1000
        });

        if (location.hash === '#policies') {
            policyRef.current.scrollIntoView();
        }

    }, []);


    return (
        <>

            <div className='h-20'></div>
            <div>
                <div className='heading-rounded m-auto text-center text-white font-bold py-4 w-11/12 md:w-3/5'>
                    Updates And Notices
                </div>

                <section className='py-12 md:py-20 flex items-center bg-[#FAFAFA]'>
                    <div className='container px-6 lg:px-0 xl:w-4/5 2xl:container mx-auto'>
                        <h2 className='font-bold auction mb-5 mt-10 mb-5'>Updates / Notices</h2>
                        <div className='border-b-4 border-green-500 bor mb-10 w-1/5 md:w-52 mb-10'></div>
                        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 pb-5 gap-6'>
                            <div className='h-full'>
                                <div className='updates-box h-full'>
                                    <p className='general mb-6'> General Meetings</p>
                                    <div className='border-b-2 border-500 w-full mb-10'></div>
                                    <Link className='text-white fy' to="/assets/pdf/20230822 Notice of AGM FY23.pdf" target='_blank'>FY 23</Link>
                                    <Link className='text-white fy' to="/agm-20th-june-2022">FY 22</Link>
                                    <div className='text-white fy'
                                        onClick={() => setModalVisle("21")}
                                    >FY 21</div>
                                    <div className='text-white fy' onClick={() => setModalVisle("20")}>FY 20</div>
                                </div>

                            </div>
                            <div className='h-full'>
                                <div className='updates-box h-full'>
                                    <p className='general mb-6'> Annual Return</p>
                                    <div className='border-b-2 border-500 w-full mb-10'></div>
                                    <a href="/assets/pdf/Form_MGT_7-2022-23-IARC-Print File.pdf" target='_blank' rel='noreferrer noopener' className='text-white fy '>FY 23</a>
                                    <a href="/assets/pdf/Form_MGT-7-website.pdf" target='_blank' rel='noreferrer noopener' className='text-white fy '>FY 22</a>
                                    <a href="/assets/pdf/Form_MGT-7-website.pdf" target='_blank' rel='noreferrer noopener' className='text-white fy '>FY 21</a>
                                    <a href="/assets/pdf/IARC-MGT-7-FY-2020.pdf" target='_blank' rel='noreferrer noopener' className='text-white fy '>FY 20</a>
                                </div>

                            </div>
                            <div className='h-full'>
                                <div className='updates-box h-full'>
                                    <p className='general mb-6'> Director’s Update</p>
                                    <div className='border-b-2 border-500 w-full mb-10'></div>
                                    <Link className='text-white fy' to="/directors-update">View</Link>
                                </div>

                            </div>
                        </div>
                    </div>
                    <Modal visible={!!modalVisible} setVisible={setModalVisle}>
                        <div className='h-15 w-1/2 bg-black flex flex-col updates-box'>
                            <div className='self-end mb-5 p-2 rounded-full flex justify-center items-center' style={{ height: "25px", width: "25px" }}>
                                <span style={{ lineHeight: 0 }} className="text-2xl">x</span>
                            </div>
                            {
                                modalVisible === "21" ?
                                    <>
                                        <Link className='text-white fy w-auto' to="/egm-22nd-july-2021">Extraordinary General Meeting held on 22nd July 2021</Link>
                                        <Link className='text-white fy' to="/agm-27th-nov-2021">Annual General Meeting held on 27th Nov 2021</Link>
                                    </>
                                    :
                                    <>
                                        <Link className='text-white fy w-auto' to="/egm-16th-feb-2021">Extraordinary General Meeting held on 16th Feb 2021</Link>
                                        <Link className='text-white fy' to="/agm-7th-sept-2020">Annual General Meeting held on 7th Sept 2020</Link>
                                    </>
                            }
                        </div>
                    </Modal>
                </section>

            </div>

        </>
    );
}

export default Investor;