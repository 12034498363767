import BODLayout from './bodLayout';

const Bodanjani = () => {

    return (
        <BODLayout
            name='Mr. Anjani Kumar'
            about={
                <>
                   Mr Anjani Kumar is an investment banker by background with 28 years of work experience. He has worked in India and abroad with multinational banks and domestic financial services firms. He has held leadership roles with Royal Bank of Scotland (RBS), CIMB and ABN AMRO, and had started his career with Price Waterhouse. Currently he is with InCred Capital. Over his career Mr Kumar has worked across Mergers & Acquisitions, Capital Markets, Private Equity, and Structured Debt arranging and has been involved in several marquee transactions in India, Asia and UK. He is also strategic advisor to some of the corporates and institutional clients.
                   Mr Kumar holds a PGDM (MBA) from IIM Bangalore (1995) and a B Tech from IIT Kanpur (1992).
                </>
            }
            img='/assets/AnjaniKumar.jpg'
            designation='Independent Director'
        />
    )
}
export default Bodanjani;