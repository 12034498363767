import './App.css';


const Agm_27nov_2021 = () => {

    return (
        <>
            <div className='h-20'></div>
            <div className='heading-rounded m-auto text-center text-white font-bold py-4 w-11/12 md:w-3/5'> AGM 27th Nov 2021</div>
            <div className='container px-6 xl:px-0 xl:w-4/5 2xl:container mx-auto py-20'>
                <p className='agm mt-10 flex justify-center'>Notice is hereby given that the 19th Annual General Meeting (AGM) of the members of International Asset Reconstruction Company Private Limited (“IARC”) will be held on Saturday, 27th November 2021 at 12 P.M. (IST) through Video Conferencing (‘VC’)/Other Audio Visual Means (‘OAVM’) facility to transact the following: -</p>
                <p className='agm mt-16 '>ORDINARY BUSINESS</p>
                <ol className='list-decimal mt-6 pl-5'>
                    <li className='agm_27_2021_li'>
                        To receive, consider and adopt the Audited Financial Statements of the Company for the financial year ended 31st March, 2021, together with the Reports of the Directors’ and Auditors’ Reports thereon. <br>
                        </br><br></br>
                        <strong>“RESOLVED THAT</strong> the Audited Financial Statements of the Company for the financial year ended 31st March, 2021, together with the Reports of the Directors and Auditors thereon be and are hereby considered, approved and adopted.”
                    </li>
                    <li className='agm_27_2021_li'>
                        To consider and if thought fit, pass the following resolution, with or without modification, as an <strong>Ordinary Resolution:</strong> <br>
                        </br> <br></br><strong>“RESOLVED THAT</strong> pursuant to the provisions of Section 139 of the Companies Act 2013 (“the Act”) read with Rule 6 of Chapter 10 of the Companies (Audit & Auditors) Rules, 2014 there under and in accordance with Article 2.1 & 11.9 of the Articles of Association of the Company M/s. S. K. Patodia & Associates, Chartered Accountants, (Firm Registration No. 112723W) be and are hereby appointed as the Statutory and Tax Auditors of the Company, to hold office from the conclusion of the Annual General Meeting for FY21 until the conclusion of the Annual General Meeting to be held for the FY24.<br></br>
                        <strong>RESOLVED FURTHER THAT </strong>the Board of Directors be and are hereby authorized to fix their remuneration and to do all such acts, deeds and things as may be required in this regard.”
                    </li>
                    <li className='agm_27_2021_li'>
                        To consider and if thought fit, pass the following resolution, with or without modification, as an <strong>Special Resolution:</strong><br></br><br></br>
                        <strong>“RESOLVED THAT</strong> pursuant to the provisions of Section 14 and other applicable provisions, if any, of Companies Act, 2013, (including any statutory modifications or re-enactment thereof, for the time being in force), and the rules framed there under, subject to the approval of the Registrar of Companies, the approval of the Members of the Company be and is hereby accorded for amending Article 11.9 of AoA of the Company to enable the company to appoint any eligible Audit Firm as the Statutory Auditor of the Company and not restrict the same to be conducted by a Big 4 Audit Firm.
                        <br></br> <br></br> <strong>RESOLVED FURTHER THAT </strong>the amended Article 11.9 be read as under:<br></br><br></br>
                        “11.9 Statutory Auditors
                        The Company shall ensure that at all the time after the closing date, the company can appoint any eligible Audit Firm as the Statutory Auditor of the Company in accordance with the Applicable Laws and RBI regulations.”<br>
                        </br><br></br><strong>RESOLVED FURTHER THAT</strong>any Director, COO and Company Secretary are hereby authorized to do all such acts, deeds and things as may be deemed expedient and incidental thereto to give effect to this resolution. <br></br><br></br>
                        <strong>RESOLVED FURTHER THAT </strong>any Director or Company Secretary be and are hereby authorized to file necessary E-Forms with Registrar of Companies and to provide the copies of the above resolutions to the concerned authorities.”
                        <br></br> <br></br>In accordance with the provisions of Section 102 of the Companies Act, 2013, the Explanatory Statement, setting out all material facts relating to the Item No. 2, 3 and 4 as mentioned in the notice is placed below:
                        <br></br> <br></br><strong>Item No. 2:</strong><br></br><br></br>(This Explanatory Statement is provided as additional information to the Members though not statutorily required as per Section 102 of the Companies Act 2013, being an ordinary business).<br>
                        </br><br></br>Pursuant to Section 139 of the Companies Act, 2013 and read with the Rules of Chapter 10 of the Companies (Audit & Auditors) Rules, 2014 there under, every company is required to appoint its Statutory Auditors for a block of five years. However, pursuant to new regulatory guidance issued by RBI vide Notification No. RBI/2021-22/25 Ref. No. DoS. CO. ARG/SEC.01/08.91.001/2021-22 dated April 27, 2021 relating to capping of the number of audits that can be conducted by an Audit Firm and their further clarity regarding applicability of the said circular to Asset Reconstruction Companies (“ARCs”) vide the FAQs issued by RBI, the ARCs will have to appoint Statutory Auditors for a continuous period of three years subject to the Auditors firms satisfying the eligibility norms each year.<br></br><br></br>
                        The term of appointment of Deloitte is set to expire at this FY21 Annual General Meeting (“AGM”). Considering the above stated RBI guidance and FAQs, Deloitte has withdrawn as Statutory Auditor of the Company w.e.f. from audit of Financial Statements for FY22. So the Company required to appoint another firm, M/s. S. K. Patodia & Associates, Chartered Accountants, as the Statutory Auditor & Tax Auditors of the Company as per the provisions of Companies Act 2013 (“the Act”), above stated RBI regularity guidance, and also inform RBI in a specified form (Form A) within one month of such appointment.<br></br><br></br>
                        The Board of Directors recommends to the members of the Company, as an ordinary resolution, the appointment of M/s. S. K. Patodia & Associates, Chartered Accountants, (Firm Registration No.112723W) as the Statutory and Tax Auditors of the Company to hold office from the conclusion of this FY21 Annual General Meeting until the conclusion of the Annual General Meeting to be held for FY24, at the remuneration as may be decided by the Board of Directors on recommendation of the Audit & Risk Committee.<br></br><br></br>
                        None of the Directors/ Key Managerial Persons (as may be applicable)/ and their relatives are concerned or interested, financial or otherwise, in the resolution set out as Item No. 2.<br></br><br></br>
                        <strong>Item No. 3:</strong>
                        <br></br>Pursuant to the Articles of Association of the company, the Board of Directors by way of circular resolution dated 18th May 2021, appointed on the Board of the Company, Mr. Jimmy Tata (DIN 06888364), as an Additional Director - Nominee Director, representing HDFC Bank Ltd, an institutional shareholder of the Company, subject to the approval from the Reserve Bank of India (RBI). The said approval from RBI was received on 20th October 2021.<br>
                        </br><br></br>Mr. Jimmy Tata holds office up to the date of this Annual General Meeting of the Company, and is eligible for appointment as a Director, pursuant to the provisions of Section 161 of the Companies Act 2013.<br></br><br></br>
                        Mr. Jimmy Tata is HDFC Bank’s Chief Credit Officer. He holds a Master’s degree in Financial<br></br><br></br>Management from the Jamnalal Bajaj Institute of Management Studies at Mumbai, and is a qualified Chartered Financial Analyst with the Institute of Chartered Financial Analysts in Hyderabad. Mr. Tata has been with the Bank since 1994, and has over 30 years of broad experience across financial sectors. Prior to joining the Bank he worked at Apple Industries Limited in various capacities in their financial services division.The Board of Directors is of the view that Mr. Tata knowledge and experience will be of immense benefit and value to the Company and, therefore, recommends his appointment as a Nominee Director of HDFC Bank Ltd., to the members as an Ordinary resolution.<br></br><br></br>
                        Mr. Tata is not disqualified from being appointed as Director in terms of Section 164 of Companies Act, 2013 and has given his consent to act as Director.<br></br><br></br>
                        Except Mr. Tata, none of the Directors and Key Managerial Personnel of the Company and their relatives are concerned or interested, financial or otherwise, in the resolution set out as Item No. 3.<br></br><br></br>
                        <strong>Item No. 4:</strong>As per the existing Article 11.9 of our Articles of Association (AoA), IARC is required to appoint only one of the Big 4 Accounting firms as auditors. The Article 11.9 states that “The Company shall ensure that at all time after the Closing Date, one of the Big 4 Firms is or remains appointed as the statutory auditor of the Company in accordance with Applicable Law.”<br></br><br></br>
                        Pursuant to new regulatory guidance issued by RBI vide Notification No. RBI/2021-22/25 Ref. No. DoS. CO. ARG/SEC.01/08.91.001/2021-22 dated April 27, 2021 relating to capping of the number
                        Pursuant to the Articles of Association of the company, the Board of Directors by way of circular resolution dated 18th May 2021, appointed on the Board of the Company, Mr. Jimmy Tata (DIN 06888364), as an Additional Director - Nominee Director, representing HDFC Bank Ltd, an institutional shareholder of the Company, subject to the approval from the Reserve Bank of India (RBI). The said approval from RBI was received on 20th October 2021.<br></br>
                        of audits that can be conducted by an Audit Firm and their further clarity regarding applicability of the said circular to Asset Reconstruction Companies (“ARCs”) vide the FAQs issued by RBI, the Company is required to amend the Article 11.9 of AoA to be in compliance of the said RBI circular, to enable the Company to appoint any eligible firm as the Statutory Auditor of the Company, in place of Big 4 Audit Firm. For appointment of the Statutory Auditors, the approval of Shareholders of the Company will be required for said amendment to AoA.<br>
                        </br><br></br> The amended Article 11.9 be read as under:<br></br><br></br>“11.9 Statutory Auditors
                        The Company shall ensure that at all the time after the closing date, the company can appoint any eligible Audit Firm as the Statutory Auditor of the Company in accordance with the Applicable Laws and RBI regulations.”<br>
                        </br><br></br>The Board recommends the resolution No. 4 specified in the Notice as Special Resolution to the Members of the Company for their approval.<br></br>
                        A copy of the Articles of Association of the Company along with the altered Article will be open for inspection by Members at the Company’s Registered Office between 11.00 A.M. and 1:00 P.M. on any working day of the Company, (Monday to Friday) upto the date previous to the date of the Annual General Meeting.<br></br>
                        None of the Directors and/or Key Managerial Personnel of the Company and/or their relatives, are concerned or interested financially or otherwise in the resolution set out at Item No. 4 of the Notice.
                    </li>
                </ol>
                <strong>Notes</strong>
                <ol className='list-decimal mt-2 pl-5 mb-14'>
                    <li className='agm_27_2021_li'>
                        An Explanatory Statement pursuant to Section 102 of the Companies Act, 2013, (‘the Act’) relating to the Special Business to be transacted at the Annual General Meeting (‘AGM’) is annexed hereto. The Board of Directors have considered and decided to include the Item No. 3 and 4 listed above
                        as Special Business in the forthcoming AGM, as it being unavoidable in nature.
                    </li>
                    <li className='agm_27_2021_li'>
                        The Ministry of Corporate Affairs (MCA), in continuation to this Ministry's General Circulars No. 14/2020 dated 8th April 2020, No.17/2020 dated 13th April 2020, No.22/2020 dated 15th June 2020, No.33/2020 dated 28th September 2020, No. 39/2020 dated 31st December 2020 and recent circular No. 10/2021 dated 23rd June 2021, after due examination, decided to allow companies to conduct their EGMs through VC or OAVM or transact items through postal ballot in accordance with the framework provided in the aforesaid Circulars upto 31st December 2021. In accordance with, the said circulars of MCA and applicable provisions of the Act, the EGM of the Company shall be conducted through VC / OAVM.
                    </li>
                    <li className='agm_27_2021_li'>
                        As the AGM shall be conducted through VC / OAVM, the facility for appointment of Proxy by the Members is not available for this AGM and hence the Proxy Form and Attendance Slip including Route Map are not annexed to this Notice.
                    </li>
                    <li className='agm_27_2021_li'>
                        Members are requested to note the designated email address <a href="mailto:iarc@iarc.co.in">iarc@iarc.co.in</a> for the conveying the vote, where a poll is required during the meeting on any resolution.
                    </li>
                    <li className='agm_27_2021_li'>
                        Institutional / Corporate Members are requested to send a scanned copy (PDF / JPEG format) of the Board Resolution authorising its representatives to attend and vote at the AGM, pursuant to Section 113 of the Act, at <a href="mailto:iarc@iarc.co.in">iarc@iarc.co.in</a>.
                    </li>
                    <li className='agm_27_2021_li'>
                        Members attending the AGM through VC / OAVM shall be counted for the purpose of reckoning the quorum under Section 103 of the Act.
                    </li>
                    <li className='agm_27_2021_li'>
                        The Directors’ Report, Auditors’ Report, Audited Financial Statements as at 31st March 2021 are enclosed.
                    </li>
                    <li className='agm_27_2021_li'>
                        The Company’s Registrar and Share Transfer Agent for its share registry (physical and electronic) are Link Intime India Private Limited having their Registered Office at 247 Park, Tower-C, 101, 1st Floor, L.B.S. Marg, Vikhroli (W), Mumbai-400 083.
                    </li>
                </ol>
            </div>
        </>
    );
}

export default Agm_27nov_2021;