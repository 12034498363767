import BODLayout from "./bodLayout";


const Bodhar = () => {

    return (
        <BODLayout
            name='Mr. Harjit Singh Bhatia'
            about={
                <>
                    Mr. Harjit Singh Bhatia founded Asia Growth Capital Advisors (AGCA) in August 2010 as a spin-off business from Credit Suisse Bank in Asia. AGCA is a private equity firm, headquartered in Singapore with a Capital Markets license from Monetary Authority of Singapore.
                    <br /><br />
                    Prior to that, Mr. Bhatia has held several senior leadership positions across various countries in Asia including as the Chairman and Managing Partner of Credit Suisse Private Equity Asia, President and CEO of General Electric’s Private Equity and Corporate Financial Services in Asia Pacific, Managing Partner & CEO at PineBridge Asia Partners, Chairman, CEO, Asia Pacific of Ritchie Capital Management and Head, Merchant Banking at Deutsche Bank. He started his career with State Bank of India and over 18 years worked in Asia and Europe and was also a founding member of SBI Caps (India’s largest Investment Bank).
                    <br /><br />
                    Overall, Mr. Bhatia has more than 48 years of broad management experience across corporate lending, private equity, distressed investing, corporate finance, infrastructure, and international finance. He has sat on Boards of several portfolio companies in the region during his career. He is well known in the industry and is a frequent participant /speaker at industry dialogues and conferences. He currently sits on the Board of Trustees of SINDA. He also Chairs the Board of International Asset Reconstruction Company (Majority owned by Blackstone with HDFC and Tata Capital as co- investors) and been Senior Advisor to Canadian Pension Plan Investment Board (CPPIB)’s Singapore investment holding company for their investments in infra space in India for over six years.
                    <br /><br />
                    Mr. Bhatia holds an MBA Degree from the University of Delhi, India and a Master of Commerce Degree from the University of Allahabad, India. He is also a Certified Associate of the Indian Institute of Bankers. Mr. Bhatia obtained a “Green Belt” certification under GE’s Six Sigma Quality Program. He is fluent in English, Hindi, Punjabi, and Urdu.
                </>
            }
            img='/assets/Group 1081107har.png'
            designation='Independent Director & Chairman of the Board'
        />
    )
};

export default Bodhar