
import './App.css';
import AOS from 'aos';
import { useEffect } from 'react';
import { Link } from 'react-router-dom'


function PolicyBox({
    img, heading, link, delay
}) {
    return (
        <Link to={link} className='flex items-center justify-center' data-aos='fade-up' data-aos-delay={delay}>
            <div className='policy-box'>
                <img src={img} alt={heading} />
                <p className='policies mt-2'>{heading}</p>
                <div className='mt-auto'>
                    <button className="bg-transparent butto hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border hover:border-transparent rounded">
                        View details
                    </button>
                </div>
            </div>
        </Link>
    );
}

const Policy = () => {

    useEffect(() => {
        AOS.init({
            duration: 1000
        });

    }, []);


    return (

        <>
            <div className='h-20'></div>
            <div className='heading-rounded m-auto text-center text-white font-bold py-4 w-11/12 md:w-3/5'>
                Policies
            </div>
            <section className='min-h-screen flex items-center bg-secondary'>
                <div className='container px-6 lg:px-0 xl:w-4/5 2xl:container mx-auto'>
                    <h2 className='font-bold auction mb-5 mt-10'>Policies</h2>
                    <div className='border-b-4 border-green-500 bor mb-10 w-1/5 md:w-52 mb-10'></div>
                    <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mb-10 gap-6'>
                        <PolicyBox
                            img='/assets/Frame 13.svg'
                            heading='Whistle Blower Policy'
                            link='/whistle-blower-policy'
                            delay='0'
                        />
                        <PolicyBox
                            img='/assets/Frame 14.svg'
                            heading='Fair Practice Code'
                            link='/fair-practice-code'
                            delay='200'
                        />
                        <PolicyBox
                            img='/assets/Frame 15.svg'
                            heading='CSR Policy'
                            link='/csr-policy'
                            delay='400'
                        />
                        <PolicyBox
                            img='/assets/Frame 16.svg'
                            heading='Anti Corruption Compliance Policy'
                            link='/anti-corruption-compliance-policy'
                            delay='600'
                        />
                        <PolicyBox
                            img='/assets/Frame 17.svg'
                            heading='Fraud Prevention & Detection Policy'
                            link='/fraud-prevention-detection'
                            delay='800'
                        />
                        <PolicyBox
                            img='/assets/Frame 18.svg'
                            heading='NR Policy'
                            link='/nr-policy'
                            delay='800'
                        />
                        <PolicyBox
                            img='/assets/code of conduct.svg'
                            heading='Code of Conduct for Directors and Senior Management'
                            link='/code-of-conduct'
                            delay='800'
                        />
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Policy