import { useEffect } from "react";
import AOS from 'aos';
import './App.css';
import { Link } from 'react-router-dom';

function PolicyBox({
    img, heading, link, delay
}) {
    return (
        <div className='flex items-center justify-center' data-aos='fade-up' data-aos-delay={delay}>
            <div className='policy-boxx text-center align-middle items-center flex'>
                <p className='policies mt-2'>{heading}</p>

            </div>
        </div>
    );
}

export default function Src() {

    useEffect(() => {
        AOS.init({
            duration: 1000
        });
    }, []);

    return (
        <>
            <section className='min-h-screen heroo flex flex-col items-center'>
                <div className='h-20'></div>
                <div className='heading-rounded mx-auto text-center text-white font-bold py-4 w-11/12 md:w-3/5'>
                    Social Responsibility Charter
                </div>
                <div className="px-6 lg:px-0 xl:w-4/5 2xl:container mx-auto h-full flex-1 flex items-center justify-start p-12">

                    <div className="flex flex-col justify-start align-start">
                        <div className='flex flex-col align-start max-w-lg' data-aos={"fade-up"}>
                            <h2 className='heading1 text-white text-4xl text-start'>Social Responsibility</h2>
                            <div className='flex justify-start'>
                                <p className='heading2'>Charter</p>
                            </div>
                            <div className='border-b-4 border-green-500 w-1/5 md:w-52  pt-4'></div>
                            <p className='text-white bannertext mt-10 mr-20  text-start'>
                                At IARC, we aim to create a sustainable future for our investors and stakeholders by integrating environmental, social and governance (ESG) principles into our business operations, processes and stewardship. Embedded in our investment philosophy, these guiding principles allow us to be a key influencer for long-term sustainable shareholder returns, adopt sustainable business practices, promote long-term economic growth and positively impact the environment and society at large.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className='min-h-screen flex items-center bg-secondary'>
                <div className='container px-6 lg:px-0 xl:w-4/5 2xl:container mx-auto'>
                    <h2 className='font-bold auction mb-5 mt-10'>Our ESG Principles</h2>
                    <div className='border-b-4 border-green-500 bor mb-10 w-1/5 md:w-52 mb-10'></div>
                    <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mb-10 gap-6'>
                        <PolicyBox
                            img='/assets/Frame 13.svg'
                            heading='We will incorporate ESG into investment analysis and decision-making processes.'
                            delay='0'
                        />
                        <PolicyBox
                            img='/assets/Frame 14.svg'
                            heading='We commit to taking initiatives to help us achieve net-zero carbon goals by 2035.'
                            delay='200'
                        />
                        <PolicyBox
                            img='/assets/Frame 15.svg'
                            heading='We commit to measuring and reducing GHG emissions from IARC business operations and increasing the use of clean energy across IARC offices.'
                            delay='400'
                        />
                        <PolicyBox
                            img='/assets/Frame 16.svg'
                            heading='We strive to be the first Asset Reconstruction Company to commence reporting Greenhouse Gas Emissions in its annual report from FY26.'
                            delay='600'
                        />
                        <PolicyBox
                            img='/assets/Frame 17.svg'
                            heading='We commit to seeking disclosure on ESG issues by the entities we invest in.'
                            delay='800'
                        />
                        <PolicyBox
                            img='/assets/Frame 17.svg'
                            heading='We commit to transparency on our ESG initiatives and goals and ensure regular engagement with stakeholders and investors on ESG matters.'
                            delay='800'
                        />
                        <PolicyBox
                            img='/assets/Frame 17.svg'
                            heading='We commit to constituting an Independent Committee consisting of internal and external members, which will guide the IARC on ESG matters.'
                            delay='800'
                        />
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </section>
        </>
    )
}
