import './App.css';


const Prevention = () => {

    return (
        <>
            <div className='h-20'></div>
            <div className='heading-rounded m-auto text-center text-white font-bold py-4 w-11/12 md:w-3/5'>Fraud Prevention & Detection Policy</div>
            <section>
                <div className='container px-6 xl:px-0 xl:w-4/5 2xl:container mx-auto py-20'>
                    <p className='name mb-10'>PREAMBLE</p>
                    <p className='pointss mb-5'>
                        Over the years, International Asset Reconstruction Company Private Limited (IARC) has implemented various policies and procedures to guide its employees, within and outside the organisation. Most of these have been formalised in the form of policy documents. These systems have been designed to ensure that officials dealing and undertaking transactions conduct the same in a transparent and uniform manner.<br></br><br></br>This policy has been drafted in accordance with the RBI Notification DOR.SIG.FIN.REC 1/26.03.001/2022-23 of April 1, 2022 (as updated from time to time) (the RBI ARC Master Circular 2022), the extant regulatory framework for ARCs issued by RBI, as amended vide RBI Notification DoR.SIG.FIN.REC.75/26.03.001/2022-23 of October 14, 2022), and with all laws and regulations that may be applicable from time to time and Reserve Bank of India Master Direction (Transfer of Loan Exposures) Directions, 2021 RBI/DOR/2021-22/86 DOR.STR.REC.51/21.04.048/2021-22 (RBI Master Direction Transfer of Loan) issued on September 24, 2021, to Bank, Financial Institutions and NBFCs (including Housing Finance Companies) (Banks/FIs). providing instructions on the matter of sale / transfer of loan exposures to financial sector entities including Asset Reconstruction Companies (ARCs).<br></br><br></br>In the industry, a variety of factors are observed to be leading to an increase in Non-Performing Assets (NPA). One of the factors for an account becoming NPA is an increase in incidences of fraudulent transactions like diversion / siphoning of funds by the borrowers. Consequently, this Fraud Prevention & Detection Policy (Policy) is established to facilitate the development of controls that will aid in the detection of fraud cases at pre-acquisition stage, and prevention of fraud against IARC post the acquisition stage.
                    </p>
                    <p className='name mb-5'>POLICY OBJECTIVES</p>
                    <p className='pointss mb-5'>
                        This Policy identifies the measures that IARC shall implement to prevent, deter and detect fraud through two fundamental elements:
                    </p>
                    <ul className='list-disc'>
                        <li className='mb-5'>
                            Identify and analyse fraud risks, and implement the processes, procedures, and controls necessary to minimise the risks and limit fraud possibilities; and
                        </li>
                        <li className='mb-5'>
                            Establish an appropriate oversight system.

                        </li>
                    </ul>
                    <p className='pointss mb-5'>
                        Specifically, this Policy aims at:
                    </p>
                    <ul className='list-disc'>
                        <li className='mb-5'>
                            Ensuring that management is aware of its responsibility for the identification and prevention of fraud and for developing procedures to prevent or detect fraud when it occurs.;
                        </li>
                        <li className='mb-5'>
                            Providing clear guidance to employees and others dealing with IARC, while acquiring any account involving fraud / loan exposures classified as fraud and the action to be taken by them when they detect or suspect any fraudulent activity vis-à-vis borrowers or accounts;
                        </li>
                        <li className='mb-5'>
                            Providing clear guidance to employees and others dealing with IARC, while acquiring any account involving fraud / loan exposures classified as fraud and the action to be taken by them when they detect or suspect any fraudulent activity vis-à-vis borrowers or accounts;
                        </li>
                        <li className='mb-5'>
                            Providing clear directions on how investigations will be conducted into fraudulent activities.
                        </li>
                        <li className='mb-5'>
                            To comply with the guidelines / circulars / notifications issued by RBI from time to time, in respect of fraud, as may be applicable to ARCs; and
                        </li>
                        <li className='mb-5'>
                            Ensuring enhancement of preventive measures and internal control procedures, subsequent to any fraud being identified, are strengthened in a speedy manner.
                        </li>
                    </ul>
                    <p className='pointss mb-5'>
                        This Policy applies to all employees and officers of IARC irrespective of their level, location, and terms of employment. This Policy would also encompass contractual staff as well as directors in the employment of IARC (collectively, Covered Personnel, as well as shareholders, agents, any intermediaries, service providers, consultants, prospective and existing customers and/or other parties with a business relationship with IARC. Any appropriate investigative activity into an instance of suspected or detected fraud will be conducted without regard to the suspected wrongdoer’s length of service, position/title, or relationship with IARC.
                    </p>
                    <ul className='list-decimal'>
                        <strong>Fraud:</strong>Fraud is a broad legal concept and is variously defined under Indian law. For instance, the Companies Act, 2013 defines fraud as any act, omission, concealment of any fact, or abuse of position committed by a person with an intent to deceive, to (a) gain undue advantage from, or (b) injure the interests of the company, its shareholders, its creditors, etc., whether or not there is any wrongful gain or wrongful loss to any person in connection with such fraud.
                        Fraud usually involves an element of deception or misrepresentation and is usually not an act or an omission committed erroneously or unintentionally, but deliberately. <br></br><br></br>In order to have uniformity in reporting instances observed from accounts, set out below are illustrations of acts or indicators of fraud.
                        <ul className='list-decimal'>
                            <li className='mb-5'>
                                Misappropriation of funds or property, and criminal breach of trust, as defined in the Indian Penal Code, 1860 (IPC).
                            </li>
                            <li className='mb-5'>
                                Fraudulent encashment through forged instruments, manipulation of books of account or through fictitious accounts and conversion of property.
                            </li>
                            <li className='mb-5'>
                                Unauthorized credit facilities extended for reward or for illegal gratification or bribes.
                            </li>
                            <li className='mb-5'>
                                Cash shortages.
                            </li>
                            <li className='mb-5'>
                                Cheating.
                            </li>
                            <li className='mb-5'>
                                Forgery.
                            </li>
                            <li className='mb-5'>
                                Fraudulent transactions involving foreign exchange.
                            </li>
                            <li className='mb-5'>
                                Any other type of fraud not coming under the specific heads as above.Fraud is however not limited to the above heads, and further indicators of fraud are also set out under the section ‘Post-Acquisition Stage’ below.Fraud will also include employee fraud. Employee fraud is a deliberate act that involves using deception to gain advantage from a position of trust or authority. Employee fraud occurs when an employee engages in fraudulent activity, such as stealing from or deceiving their employer, in order to extract some form of financial or personal benefit. Employee frauds may also result by way of misstatement of Financial Statement.
                            </li>
                        </ul>
                        <li className='mb-5'>
                            <strong>Transferor:</strong> means the entity which transfers economic interest in a loan exposure under the applicable regulatory directions.
                            Explanation: In market parlance, transferor may be alternatively referred to as the assignor under assignment transactions and grantor under loan participations, wherever applicable.I
                        </li>
                        <li className='mb-5'>
                            Loan participation means a transaction through which the transferor transfers all or part of its economic interest in a loan exposure to transferee(s) without the actual transfer of the loan contract, and the transferee(s) fund the transferor to the extent of the economic interest transferred which may be equal to the principal, interest, fees, and other payments, if any, under the transfer agreement.Provided that the transfer of economic interest under a loan participation shall only be through a contractual transfer agreement between the transferor and transferee(s) with the transferor remaining as the lender on record.Provided further that in case of loan participation, the exposure of the transferee(s) shall be to the underlying borrower and not to the transferor. Accordingly, the transferor and transferee(s) shall maintain capital according to the exposure to the underlying borrower calculated based on the economic interest held by each post such transfer. The applicable prudential norms, including the provisioning requirements post the transfer, shall be based on the above exposure treatment and the consequent outstanding.
                        </li>
                    </ul>
                    <p className='name mb-5'>
                        SALE OF NPA CLASSIFIED AS FRAUD
                    </p>
                    <p className='pointss mb-5'>
                        As per the RBI Master Direction Transfer of Loan, stressed loans held by Banks/FIs which are in default for more than 60 days or classified as NPAs are permitted to be transferred to ARCs.<br></br>This shall include loan exposures classified as fraud as on the date of transfer provided that the responsibilities of the transferor with respect to continuous reporting, monitoring, filing of complaints with law enforcement agencies and proceedings related to such complaints shall also be transferred to the ARC.<br></br><br></br>The transfer of such loan exposures to an ARC, however, does not absolve the transferor from fixing their staff’s accountability as required under the extant regulatory directives on frauds.
                    </p>
                    <p className='name mb-5'>
                        PRE-ACQUISITION STAGE

                    </p>
                    <p className='pointss mb-5'>
                        IARC, including its Covered Personnel, shall check the records of the Banks/FIs to assess whether the borrower/guarantor involved has been involved in any incidence of frauds and also check if the Banks/FIs have reported such borrower/guarantor as fraud in accordance with RBI directions / circulars / guidelines issued from time to time. IARC, including its Covered Personnel, shall also take proper care while conducting the due diligence on a borrower/guarantor with respect to prior history or incidence of fraudulent acts before the acquisition of an NPA from Banks/FIs.
                    </p>
                    <p className='name mb-5'>
                        POST-ACQUISITION STAGE
                    </p>
                    <p className='pointss mb-5'>
                        While fraudulent activity carried out after acquisition of an NPA from Banks/FIs could have a very wide range of coverage, fraud may include without limitation some of the following acts:
                    </p>
                    <ul className='list-disc pointss'>
                        <li className='mb-5'>
                            Misappropriation of funds, securities, supplies or other assets
                        </li>
                        <li className='mb-5'>
                            Misappropriation of funds, securities, supplies or other assets
                        </li>
                        <li className='mb-5'>
                            Forgery or alteration of a cheque, bank draft, account, or any other financial instrument
                        </li>
                        <li className='mb-5'>
                            Fraudulent financial reporting
                        </li>
                        <li className='mb-5'>
                            Mis-utilization of the borrower/ guarantor’s funds for personal purposes or for purposes other than for which the loan was sanctioned
                        </li>
                        <li className='mb-5'>
                            Making false written or oral statements or representation with respect to its activities
                        </li>
                        <li className='mb-5'>
                            Impropriety in the handling or reporting of money or financial transactions
                        </li>
                        <li className='mb-5'>
                            Profiteering as a result of insider knowledge of the borrower’s or the guarantor’s activities
                        </li>
                        <li className='mb-5'>
                            Disclosing confidential and proprietary information to unauthorized parties
                        </li>
                        <li className='mb-5'>
                            Bribery or kickbacks
                        </li>
                        <li className='mb-5'>
                            Any other act that falls under the scope of fraudulent activity, as detailed above
                        </li>
                    </ul>
                    <p className='name mb-5'>
                        INTERNAL FRAUDS
                    </p>
                    <p className='pointss mb-5'>
                        If any fraud is reported within IARC, investigation may be initiated based on-
                    </p>
                    <ul className='pointss list-disc'>
                        <li className='mb-5'>
                            a complaint, or
                        </li>
                        <li className='mb-5'>
                            whistle-blower statement, or
                        </li>
                        <li className='mb-5'>
                            Suo moto by IARC
                        </li>
                    </ul>
                    <p className='pointss mb-5'>
                        IARC shall constitute the Investigation through an internal committee that will comprise of Deputy CEO, COO and President (e.g., employees at least one level above, chaired by someone 2 levels above; supervisor of the employee not to be included in the committee, etc.) followed by a review by MD & CEO<br></br><br></br>If the employee with level of KMPs/CEO, Dy CEO, COO/ President is involved in any fraud, then the same shall be informed to the Audit & Risk Committee and the Audit & Risk Committee will preside over the investigation. If any other employees are involved in any fraud, then the same shall be investigated by the Internal Committee.<br></br><br></br>In the extreme case where the fraud is of a very serious nature, the employee shall be terminated.<br></br><br></br>All termination decisions related to employees at a level of KMPs/CEO, Dy CEO, COO/ President shall be approved by the Board of Directors. For other employee’s termination decisions shall be approved by the Managing Director & Chief Executive Officer.
                    </p>
                    <p className='name mb-5'>
                        REPORTING OF FRAUD
                    </p>
                    <p className='pointss mb-5'>
                        Covered Personnel and other parties to whom this Policy applies (by virtue of their having a business relationship with IARC) must report such incident(s) as soon as he / she comes to know of any fraud or suspected fraud. Such reporting shall be made to the designated Nodal Officer(s) i.e., Company Secretary & Compliance Officer.<br></br><br></br>As per RBI ARC Master Circular 2022, IARC shall report to Indian Banks’ Association (IBA) the details of Chartered Accountants, Advocates and Valuers (who have committed serious irregularities in the course of rendering their professional services) for including in the IBA database of Third-Party Entities involved in fraud. However, IARC will have to ensure that they follow meticulously the procedural guidelines issued by IBA (Circular No. RB-II/Fr./Gen/3/1331 dated August 27, 2009) and also provide the parties a fair opportunity to explain their position and justify their action before reporting to IBA. If no reply/ satisfactory clarification is received from them within one month, IARC shall report their details to IBA. IARC should consider this aspect before assigning any work to such parties in future.
                    </p>
                    <p className='name mb-5'>
                        INVESTIGATING FRAUD
                    </p>
                    <p className='pointss mb-5'>
                        Upon notice or discovery of a suspected instance of fraud, IARC will immediately initiate an investigation. IARC will make every effort to maintain the confidentiality of such investigation.<br></br><br></br>After conducting an initial examination, if it is determined that the alleged or suspected fraud merits further investigation, IARC reserves all rights including regarding escalating the matter to the appropriate authorities and regulators. In the event of any prima facie or determined fraud, IARC shall also ensure that where required and appropriate, relevant enhancements of IARC’s internal controls and procedures be carried out to prevent a recurrence of such instances.
                    </p>
                    <p className='name mb-5'>
                        DISCLOSURE
                    </p>
                    <p className='pointss mb-5'>
                        This Policy shall be placed on IARC’s website.This Policy shall be placed on IARC’s website.
                    </p>
                    <p className='name mb-5'>
                        REVIEW
                    </p>
                    <p className='pointss mb-5'>

                        The Fraud Prevention & Detection Policy shall be placed for review by the Board every two years, or as needed.
                    </p>
                </div>
            </section>
        </>
    )
}
export default Prevention