import AOS from "aos";
import { useEffect } from "react";

export default function BODLayout({ about, name, designation, img }) {

    useEffect(() => {
        AOS.init({
            duration: 1000
        });
    }, []);

	return (
		<>
			<section>
				<div className='h-20'></div>
				<div className='heading-rounded m-auto text-center text-white font-bold py-4 w-11/12 md:w-3/5'>
					Board of Directors
				</div>
				<div className='container px-6 xl:px-0 xl:w-4/5 2xl:container mx-auto py-20'>
					<div className='wilful flex items-center'>
						<div className='grid grid-cols-12 gap-y-10 md:gap-x-10'>
							<div className='hidden md:block md:col-span-6' data-aos='fade-up'>
								<p className='points' style={{textAlign:'justify'}}>
									{about}
								</p>
							</div>
							<div className='col-span-12 md:col-span-6' data-aos='fade-up' data-aos-delay='100'>
								<div className='flex justify-center'>
									<img src={img} alt='image' />
								</div>
								<div className='bod text-center'>
									<p className='bodhead mt-5'>
										{name}
									</p>
									<p className='bodpara mt-5'>
										{designation}
									</p>
								</div>
							</div>
							<div className='col-span-12 md:hidden'>
								<p className='points'>
									{about}
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}